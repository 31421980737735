import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getIn, useFormik } from "formik";
import * as Yup from 'yup';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, FormControl, FormControlLabel, FormHelperText, Grid, ListItemText, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { StorageItem, Storages } from "../../../models/StorageModels";
import { toDateTimeString } from "../../../utils/dateUtils";
import { StorageItemSchema } from "../../../models/StorageModelsSchemas";
import { GridItem } from "../../common/CommonStyles";
import * as StorageItemUtils from "../../../utils/StorageItemUtils";

interface Props {
  storageItems: StorageItem[],
  updateStorageItems: (storageItems: StorageItem[]) => void,
  onSubmit: (storageItems: StorageItem[]) => void,
  cancel: () => void
}

const SelectStorageView: React.FC<Props> = ({storageItems, updateStorageItems, onSubmit, cancel}) => {
  const { t } = useTranslation(["translation"]);
  const [expandedRow, setExpandedRow] = useState<number|undefined>(undefined);

  const submit = (values: {items: StorageItem[]}) => {
    onSubmit(values.items);
  }

  const formik = useFormik({
    initialValues: { items: storageItems },
    validationSchema: Yup.object({
      items: Yup.array().of(StorageItemSchema).required('Required')
    }),
    onSubmit: submit
  });

  const handleExpanded = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedRow(() => isExpanded ? index : undefined);
  };

  const getErrorMessage = (errorKey?: string) => {
    if (errorKey === "minValueError") {
      return t("common.validation.minValueError") + "1";
    }
    if (errorKey === "storageAmountError") {
      return t("app.components.SelectStorageView.storageAmountError");
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  const getSummaryContent = (item: StorageItem, itemIndex: number) => {
    if (isInStorage(itemIndex)) {
      if (itemHasErrors(itemIndex)) {
        return <ListItemText primaryTypographyProps={{variant: "button", align: "right", color: tokens.colors.alertBase}} primary={t(`app.components.SelectStorageView.fixItemErrors`)} />;
      }
      else if (!item.Storages[0].LocationId) {
        return <ListItemText primaryTypographyProps={{variant: "button", align: "right"}} primary={t("app.components.SelectStorageView.title")} />;
      }
      else {
        return <ListItemText primaryTypographyProps={{variant: "button", align: "right"}} primary={t("app.components.SelectStorageView.storageOk")} />
      }
    }
    return <ListItemText primaryTypographyProps={{variant: "button", align: "right"}} primary={t("app.components.SelectStorageView.waitingForDelivery")} />;
  }

  const itemHasErrors = (itemIndex: number) => {
    return Boolean(getIn(formik.errors, `items[${itemIndex}]`));
  }

  const isInStorage = (itemIndex: number) => {
    return formik.values.items[itemIndex].Storages.length > 0;
  }

  const addStorageLocation = (itemIndex: number) => {
    const items = formik.values.items;
    const existingAmount = StorageItemUtils.getQuantityInStorages(formik.values.items[itemIndex]);
    const invoicedQuantity = StorageItemUtils.getInvoicedQuantity(formik.values.items[itemIndex]);
    const remainingAmount = existingAmount <= invoicedQuantity ? invoicedQuantity - existingAmount : 0;
    items[itemIndex].Storages.push({
      Amount: remainingAmount,
      LocationId: "",
      UpdatedAt: toDateTimeString(new Date())
    });
    formik.setFieldValue("items", items);
  }

  const deleteStorageLocation = (itemIndex: number, storageIndex: number) => {
    const items = formik.values.items;
    items[itemIndex].Storages.splice(storageIndex, 1);
    formik.setFieldValue("items", items);
  }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <Typography variant="button">{t("app.components.SelectStorageView.title")}</Typography>
        { formik.values.items.map((item, itemIndex) => (
          <Accordion
            key={`storage-item-${itemIndex}`}
            expanded={expandedRow === itemIndex}
            onChange={handleExpanded(itemIndex)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Grid container>
                <GridItem item xs>
                  <ListItemText primaryTypographyProps={{variant: "button"}} primary={`${item.ArticleName}, ${item.InvoicedQuantity.Value} ${item.InvoicedQuantity._QuantityUnitCode}`} secondary={item.InvoicedObjectID?.Value} />
                </GridItem>
                <GridItem item xs={5}>
                  { getSummaryContent(item, itemIndex) }
                </GridItem>
              </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{padding: 0}}>
              { isInStorage(itemIndex) ? 
              <Box >
                { item.Storages.map((storage, storageIndex) => {
                  const locationIdFieldName = `items[${itemIndex}].Storages[${storageIndex}].LocationId`;
                  const amountFieldName = `items[${itemIndex}].Storages[${storageIndex}].Amount`;
                  return (
                  <Card key={`item-${itemIndex}-storage-${storageIndex}`} sx={{padding: tokens.spacing.xs, marginBottom: tokens.spacing.xxs, background: tokens.colors.highlightLight2}}>
                    <Grid container>
                      <GridItem item xs="auto">
                        <FormControl>
                          <RadioGroup
                            name={locationIdFieldName}
                            value={storage.LocationId ?? ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            sx={{justifyContent: "flex-end"}}
                          >
                            { Storages.map((storage) => (
                              <FormControlLabel key={`storage-radio-${storage.id}`} value={storage.id} control={<Radio sx={{padding: tokens.spacing.xxs}} />} label={`${storage.id} - ${storage.name}`}/>
                            ))}
                          </RadioGroup>
                          { Boolean(getIn(formik.touched, locationIdFieldName)) && Boolean(getIn(formik.errors, locationIdFieldName)) && 
                            <FormHelperText sx={{color: tokens.colors.alertBase}}>{t(`common.validation.${getIn(formik.errors, locationIdFieldName)}`)}</FormHelperText> 
                          }
                        </FormControl>
                      </GridItem>
                      <Grid item xs sx={{display: "flex", flexDirection: "column", alignItems: "flex-end", justifyContent: "space-between"}}>
                        <FormControl>
                          <Typography variant="caption">{`${t("app.components.SelectStorageView.amountInStorage")} - ${item.InvoicedQuantity._QuantityUnitCode}`}</Typography>
                          <TextField
                            name={amountFieldName}
                            type="number"
                            sx={{background: tokens.colors.whiteBase}}
                            value={storage.Amount}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={Boolean(getIn(formik.touched, amountFieldName)) && Boolean(getIn(formik.errors, amountFieldName))}
                            size="small"
                          />
                          { Boolean(getIn(formik.touched, amountFieldName)) && Boolean(getIn(formik.errors, amountFieldName)) &&
                            <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(getIn(formik.errors, amountFieldName))}</FormHelperText> 
                          }
                          { typeof(getIn(formik.errors, `items[${itemIndex}].Storages`)) === "string" &&
                            <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(getIn(formik.errors, `items[${itemIndex}].Storages`))}</FormHelperText> 
                          }
                        </FormControl>
                        { storageIndex > 0 &&
                          <Button color="error" onClick={() => deleteStorageLocation(itemIndex, storageIndex)}>
                            {t("common.actions.delete.title")}
                          </Button>
                        }
                      </Grid>
                    </Grid>
                  </Card>
                  )}
                )}
                <Button variant="text" size="small" onClick={() => addStorageLocation(itemIndex)}>
                  <Typography>{t("app.components.SelectStorageView.addStorage")}</Typography>
                </Button>
              </Box> 
              :
              <Grid container>
                <GridItem item xs={12}>
                  <Card sx={{width: "100%", padding: tokens.spacing.xs}}>
                    <Typography>{t("app.components.SelectStorageView.shippingMessage")}</Typography>
                  </Card>
                </GridItem>
              </Grid>
              }
            </AccordionDetails>
          </Accordion>
        ))}
        <Box sx={{display: "flex", width: "100%", justifyContent: "space-between", marginTop: tokens.spacing.xxs, marginBottom: tokens.spacing.xxs}}>
          <Button
            variant="contained"
            onClick={cancel}
          >
            {t("common.actions.cancel.title")}
          </Button>
          <Button
            type="submit"
            variant="contained"
          >
            {t("app.components.SelectStorageView.saveStorageLocations")}
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default SelectStorageView;