import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XMLParser } from "fast-xml-parser";
import { Finvoice, XmlRoot } from '../../../models/FinvoiceModels';
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import InvoiceListView from './InvoiceListView';
import * as FinvoiceApi from "../../../api/FinvoiceApi";
import { Alert, AlertTitle, Box } from '@mui/material';
import { DeliveryState, InvoiceImportRow, StorageItem, StorageItemState, StorageItemStateEnum } from '../../../models/StorageModels';
import SelectStorageView from './SelectStorageView';
import { toDateTimeString } from '../../../utils/dateUtils';
import LoadingModal from '../../modals/LoadingModal';

enum InvoicesAction {
  LISTVIEW = "ListView",
  STORAGE = "Storage"
}

export interface SelectedInvoiceRows {
  invoice: Finvoice,
  rows: InvoiceImportRow[]
}

const InvoicesView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [invoices, setInvoices] = useState<Finvoice[]>([]);
  const [expandedInvoice, setExpandedInvoice] = React.useState<number|undefined>(undefined);
  const [selectedAction, setSelectedAction] = useState<InvoicesAction>(InvoicesAction.LISTVIEW);
  const [itemsToImport, setItemsToImport] = useState<StorageItem[]>([]);
  const [newItemsInStorage, setNewItemsInStorage] = useState<StorageItem[]|undefined>(undefined);
  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    FinvoiceApi.GetFile("invoices.json")
    .then(res => {
      if (Array.isArray(res.data)) {
        const parser = new XMLParser({ignoreAttributes: false, attributeNamePrefix: "_", textNodeName: "Value"});
        const invoicePromises = res.data.map(async it => {
          return FinvoiceApi.GetFile(it)
          .then(r => {
            const xml = parser.parse(r.data) as XmlRoot;
            return xml.Finvoice;
          });
        });
        Promise.all(invoicePromises).then(values => {
          setInvoices(() => values);
        });
      }
    })
  }, []);

  const handleExpanded = (index: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpandedInvoice(() => isExpanded ? index : undefined);
  };
  
  const importRows = (selected: SelectedInvoiceRows) => {
    const now = toDateTimeString(new Date());
    setShowNotification(() => false);
    const items = selected.rows.filter(r => r.selected).map((row) => {
      const invoiceRow = selected.invoice.InvoiceRow[row.rowIndex];
      const deliveryStatus: DeliveryState[] = row.status === StorageItemStateEnum.STORAGE ? [
        {
          Amount: parseInt(invoiceRow.InvoicedQuantity.Value),
          CreatedAt: now
        }
      ] : [];
      const storageStatus: StorageItemState[] = row.status === StorageItemStateEnum.STORAGE ? [
        {
          Amount: parseInt(invoiceRow.InvoicedQuantity.Value),
          LocationId: "",
          UpdatedAt: now
        }
      ] : [];
      const storageItem: StorageItem = {
        ...invoiceRow,
        Deliveries: deliveryStatus,
        Storages: storageStatus,
        Usages: [],
        InvoiceNumber: selected.invoice.InvoiceDetails.InvoiceNumber,
        InvoiceRowNumber: row.rowIndex,
        CreatedAt: toDateTimeString(new Date())
      }
      return storageItem;
    });
    const newItemsToStorage = items.filter(i => i.Storages.length > 0);
    if (newItemsToStorage.length > 0) {
      setItemsToImport(() => items);
      setSelectedAction(() => InvoicesAction.STORAGE);
    }
    else {
      saveStorageItems(items);
    }
  }

  const saveStorageItems = (storageItems: StorageItem[]) => {
    setLoading(() => true);
    appContext.saveStorageItemsAsync(storageItems)
    .then(savedItems => {
      setShowNotification(() => true);
      setItemsToImport(() => []);
      setSelectedAction(() => InvoicesAction.LISTVIEW);
    })
    .finally(() => setLoading(() => false));
  }

  const cancelImportRows = () => {
    setItemsToImport(() => []);
    setSelectedAction(() => InvoicesAction.LISTVIEW);
  }

  return (
    <Page>
      { showNotification && 
        <Alert severity="success" onClose={()=> setShowNotification(() => false)}>
          <AlertTitle>{t("app.components.InvoicesView.importSuccessTitle")}</AlertTitle>
          {t("app.components.InvoicesView.importSuccessMsg")}
        </Alert>
      }
      { selectedAction === InvoicesAction.LISTVIEW && 
        <Box>
          { invoices.map((invoice, invoiceIndex) => (
            <InvoiceListView
              key={`invoice-${invoiceIndex}`}
              invoiceIndex={invoiceIndex}
              invoice={invoice}
              expandedIndex={expandedInvoice}
              handleExpanded={handleExpanded}
              importRows={importRows}
            />
          ))}
        </Box>
      }
      { selectedAction === InvoicesAction.STORAGE && itemsToImport.length > 0 && 
        <SelectStorageView 
          storageItems={itemsToImport}
          updateStorageItems={(storageItems: StorageItem[]) => setItemsToImport(() => storageItems)}
          onSubmit={saveStorageItems}
          cancel={cancelImportRows}
        /> 
      }
      <LoadingModal isOpen={loading} overLay={2000} contentLabel={"Saving"} message={t("common.savingState")}/>
    </Page>
  );
}

export default InvoicesView;