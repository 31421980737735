import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, ButtonBase, Card, Chip, CircularProgress, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import { StorageItem, UsageLocations } from '../../../models/StorageModels';
import { getStorageLocations } from '../../../utils/StorageItemUtils';
import StorageItemModal from './StorageItemModal';
import TablePaginationActions from '../../common/TablePaginationActions';

const TableCellTight = styled(TableCell)`
  padding: 0
`

const StorageLocation: React.FC<{storageItem: StorageItem}> = ({storageItem}) => {
  const locations = getStorageLocations(storageItem);
  if (locations.length === 0) {
    return <HorizontalRuleIcon/>;
  }
  else if (locations.length === 1) {
    return <Typography variant="caption">{locations[0]}</Typography>;
  }
  return (
    <Typography variant="caption">{locations.join(", ")}</Typography>
  )
}

const StorageListView: React.FC = () => {
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedStorageItem, setSelectedStorageItem] = useState<StorageItem|undefined>(undefined);
  const [keyword, setKeyword] = useState<string>("");
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<StorageItem[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const addKeyword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (keyword && searchFilters.findIndex(it =>it === keyword) < 0) {
      setSearchFilters(old => {
        const filters = [...old];
        filters.push(keyword);
        return filters;
      });
      setKeyword(() => "");
    }
  }

  const deleteKeyword = (index: number) => {
    setSearchFilters(old => {
      const filters = [...old];
      filters.splice(index, 1);
      return filters;
    });
  }

  useEffect(() => {
    if (appContext.loggedIn) {
      setLoading(() => true);
      appContext.getStorageItemsAsync()
      .finally(() => setLoading(() => false));
    }
  }, []);

  useEffect(() => {
    let filteredItems = appContext.storageItems.filter(item => {
      // Storage item must match with all filters to be added to search results
      const res = searchFilters.reduce((match, keyword) => {
        const keywordLower = keyword.toLowerCase();
        // If there is at least one filter that does not match, discard item from search results
        if (!match) return false;
        // It is enough that one of the following is true
        const batchNumberMatch = item.InvoicedObjectID!.Value.toLowerCase().search(keywordLower) >= 0;
        if (batchNumberMatch) {
          return true;
        }
        const articleNameMatch = item.ArticleName.toLowerCase().search(keywordLower) >= 0;
        if (articleNameMatch) {
          return true;
        }
        const articleIdentifierMatch = item.ArticleIdentifier.toLowerCase().search(keywordLower) >= 0;
        if (articleIdentifierMatch) {
          return true;
        }
        const storageLocationMatch = item.Storages.findIndex(storage => storage.LocationId.toLowerCase() === keywordLower) >= 0;
        if (storageLocationMatch) {
          return true;
        }
        const usageLocationsMatch = UsageLocations.filter(loc => loc.id.toLowerCase().search(keywordLower) >= 0 || loc.name.toLowerCase().search(keywordLower) >= 0);
        const usageLocationMatch = item.Usages.findIndex(usage => usageLocationsMatch.findIndex(loc => loc.id === usage.UsageLocationId) >= 0) >= 0;
        if (usageLocationMatch) {
          return true;
        }
        return false;
      }, true);
      return res;
    });
    setSearchResults(() => filteredItems);
  }, [appContext.storageItems, searchFilters]);
  
  return (
    <Page>
      <form onSubmit={(e) => addKeyword(e)} autoComplete="off">
        <Box sx={{display: "flex"}}>
          <TextField
            label={t("app.components.StorageListView.searchByKeyword")}
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            size="small"
            sx={{flexGrow: 1}}
          />
          <IconButton color="primary" type="submit">
            <SearchOutlinedIcon/>
          </IconButton>
        </Box>
      </form>
      <Typography variant="body2" color={tokens.colors.brandBase} >{t("app.components.StorageListView.searchHelptext")}</Typography>
      { searchFilters.length > 0 && 
        <Box sx={{marginTop: tokens.spacing.xs}}>
          { searchFilters.map((filter, filterIndex) => 
            <Chip key={`filter-${filterIndex}`} label={filter} onDelete={() => deleteKeyword(filterIndex)} sx={{marginRight: tokens.spacing.xxs}}/>
          )}
        </Box>
      }
      <TableContainer component={Card} sx={{width: "100%"}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellTight>{t("app.components.StorageListView.batchNumber")}</TableCellTight>
              <TableCellTight align="center">{t("app.components.StorageListView.timestamp")}</TableCellTight>
              <TableCellTight align="center">{t("app.components.StorageListView.article")}</TableCellTight>
              <TableCellTight align="center">{t("app.components.StorageListView.storage")}</TableCellTight>
              <TableCellTight align="right">{t("app.components.StorageListView.amount")}</TableCellTight>
            </TableRow>
          </TableHead>
          <TableBody>
            { loading && 
              <TableRow>
                <TableCellTight colSpan={5} align="center">
                  <CircularProgress sx={{margin: tokens.spacing.xxs}}/>
                </TableCellTight>
              </TableRow>
            }
            { (rowsPerPage > 0
                ? searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : searchResults
              ).map((item, itemIndex) => (
              <ButtonBase
                key={itemIndex}
                component={TableRow}
                onClick={() => setSelectedStorageItem(() => item)}
                sx={{display: "table-row", background: (itemIndex % 2 ? tokens.colors.depthLight2 : tokens.colors.depthLight3)}}
                >
                <TableCellTight component="th" scope="row">{item.InvoicedObjectID?.Value}</TableCellTight>
                <TableCellTight align="center">{item.CreatedAt}</TableCellTight>
                <TableCellTight align="center">{item.ArticleName}</TableCellTight>
                <TableCellTight align="center"><StorageLocation storageItem={item}/></TableCellTight>
                <TableCellTight align="right">{item.InvoicedQuantity.Value}</TableCellTight>
              </ButtonBase>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={searchResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
        {selectedStorageItem && 
          <StorageItemModal isOpen={!!selectedStorageItem} storageItem={selectedStorageItem} handleClose={() => setSelectedStorageItem(() => undefined)} />
        }
      </TableContainer>
    </Page>
  );
}

export default StorageListView;