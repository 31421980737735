import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DeliveryDateClass, Finvoice } from '../../../models/FinvoiceModels';
import { toDateString } from '../../../utils/dateUtils';
import { SelectedInvoiceRows } from './InvoicesView';
import InvoiceImportForm from './InvoiceImportForm';
import { InvoiceImportRow } from '../../../models/StorageModels';
import { GridItem } from '../../common/CommonStyles';

interface Props {
  invoiceIndex: number;
  invoice: Finvoice;
  expandedIndex: number|undefined;
  handleExpanded: (invoiceIndex: number) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  importRows: (selected: SelectedInvoiceRows) => void;
}

const InvoiceListView: React.FC<Props> = ({invoiceIndex, invoice, expandedIndex, handleExpanded, importRows}) => {

  const getImportableRows = (invoice: Finvoice) => {
    return invoice.InvoiceRow.filter(f => f.ArticleIdentifier !== "Rahti24%");
  }

  const formatInvoiceDate = (invoiceDate: DeliveryDateClass) => {
    if (invoiceDate && invoiceDate._Format === "CCYYMMDD") {
      const d = invoiceDate.Value.toString();
      const formattedDateString = `${d.substring(0, 4)}-${d.substring(4, 6)}-${d.substring(6)}`;
      const date = new Date(formattedDateString);
      return toDateString(date);
    }
    return invoiceDate?.Value;
  }

  const onSubmit = (rows: InvoiceImportRow[]) => {
    importRows({ invoice, rows })
  }

  return (
    <Accordion expanded={expandedIndex === invoiceIndex} onChange={handleExpanded(invoiceIndex)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <GridItem item xs={6}>
            <Typography variant="button">{invoice.SellerPartyDetails.SellerOrganisationName}</Typography>
          </GridItem>
          <GridItem item xs={6}>
            <Typography variant="button">{formatInvoiceDate(invoice.InvoiceDetails.InvoiceDate)}</Typography>
          </GridItem>
        </Grid>
      </AccordionSummary>
      <AccordionDetails sx={{padding: 0}}>
        <InvoiceImportForm rows={getImportableRows(invoice)} onSubmit={onSubmit} />
      </AccordionDetails>
    </Accordion>
  );
}

export default InvoiceListView;