import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Page from '../common/Page';

const PageNotFoundView: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t("app.components.PageNotFoundView.title")}>
      <Link to={"/"}>{t("app.components.PageNotFoundView.description")}</Link>
    </Page>
  );
}

export default PageNotFoundView;