import { InvoiceRow } from "./FinvoiceModels";

export interface StorageItem extends InvoiceRow {
  OmaXId?: string,
  Deliveries: DeliveryState[],
  Storages: StorageItemState[],
  Usages: UsageItemState[],
  InvoiceNumber: string,
  InvoiceRowNumber: number,
  CreatedAt: string,
}

export interface DeliveryState {
  Amount: number,
  CreatedAt: string
}

export interface StorageItemState {
  Amount: number,
  LocationId: string,
  UpdatedAt: string
}

export interface UsageItemState {
  Amount: number,
  StorageLocationId: string,
  UsageLocationId: string,
  UpdatedAt: string
}

export enum StorageItemStateEnum {
  SHIPPING = "shipping",
  STORAGE = "storage",
  FIELD = "field"
}

export enum StorageTypeEnum {
  WAREHOUSE = "halli",
  SILO = "siilo"
}

export interface InvoiceImportRow {
  rowIndex: number,
  selected: boolean,
  status?: string
}

export interface InvoiceImportFormModel {
  rows: InvoiceImportRow[]
}

export interface StorageLocation {
  id: string,
  name: string,
  type: StorageTypeEnum,
  description: string
}

export interface UsageLocation {
  id: string,
  name: string
}

export const Storages: StorageLocation[] = [
  {
    id: "A1",
    name: "Tuotevarasto 1",
    type: StorageTypeEnum.WAREHOUSE,
    description: "Varastohalli ostettujen siementen varastointiin"
  },
  {
    id: "A2",
    name: "Tuotevarasto 2",
    type: StorageTypeEnum.WAREHOUSE,
    description: "Varastohalli ostettujen siementen varastointiin"
  },
  {
    id: "B1",
    name: "Konehalli 1",
    type: StorageTypeEnum.WAREHOUSE,
    description: "Konehalli"
  },
  {
    id: "S1",
    name: "Viljasiilo 1",
    type: StorageTypeEnum.SILO,
    description: "Viljasiilo myytävien siementen varastointiin"
  },
  {
    id: "S2",
    name: "Viljasiilo 2",
    type: StorageTypeEnum.SILO,
    description: "Viljasiilo myytävien siementen varastointiin"
  },
]

export const UsageLocations: UsageLocation[] = [
  {
    id: "776012340",
    name: "Joen viereinen palsta"
  },
  {
    id: "776012341",
    name: "Joen takana oleva palsta"
  },
  {
    id: "776012342",
    name: "Tupa keskellä"
  },
  {
    id: "776012343",
    name: "Paras heinäpelto"
  },
  {
    id: "776012344",
    name: "Kesälaidun"
  },
  {
    id: "776012345",
    name: "Rantapelto"
  },
  {
    id: "776012346",
    name: "Mäen alla oleva palsta"
  },
  {
    id: "776012347",
    name: "Koivuhaka"
  }
]

export interface ProfitFactor {
  articleIdentifier: string,
  sowingRate: number, // kylvömäärä kg/ha
  harvestExpectation: number, // sato-odotus kg/ha
  price: number // hinta €/tn
}

export const ArticleProfits: ProfitFactor[] = [
  {
    articleIdentifier: "LHINGRID",
    sowingRate: 230,
    harvestExpectation: 4000,
    price: 320
  },
  {
    articleIdentifier: "LKKONTIO",
    sowingRate: 200,
    harvestExpectation: 4500,
    price: 200
  }
]