import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, FormControl, Grid, Modal, TextField, Typography } from "@mui/material";
import {suomifiDesignTokens as tokens} from "suomifi-ui-components";
import { useAppStateContext } from "../../../state/AppStateContext";
import { DeliveryState, StorageItem, StorageItemState, UsageItemState } from "../../../models/StorageModels";
import { useFormik } from "formik";
import { StorageItemSchema } from "../../../models/StorageModelsSchemas";
import * as StorageItemUtils from "../../../utils/StorageItemUtils";
import AddDeliveryDialog from "./AddDeliveryDialog";
import { GridItem } from "../../common/CommonStyles";
import LoadingModal from "../../modals/LoadingModal";
import AddUsageDialog from "./AddUsageDialog";

interface Props {
  isOpen: boolean,
  storageItem: StorageItem,
  handleClose: () => void
}

const StorageItemModal: React.FC<Props> = ({isOpen, storageItem, handleClose}) => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();
  const [openAddDeliveryDialog, setOpenAddDeliveryDialog] = useState<boolean>(false);
  const [openAddUsageDialog, setOpenAddUsageDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const saveStorageItem = () => {

  }

  const formik = useFormik({
    initialValues: storageItem,
    validationSchema: StorageItemSchema,
    onSubmit: saveStorageItem
  })

  const getWaitingForDelivery = (): number => {
    const delivered = StorageItemUtils.getDeliveredQuantity(formik.values);
    const invoiced = StorageItemUtils.getInvoicedQuantity(formik.values);
    return invoiced - delivered;
  }

  const getDelivered = () => {
    const waitingForDelivery = getWaitingForDelivery();
    return waitingForDelivery > 0 ? 
    (
      <GridItem container item xs={12}>
        <GridItem item xs={6}>
          <Typography>{t("app.components.StorageItemModal.waitingForDelivery")}</Typography>
        </GridItem>
        <GridItem item xs={6}>
          <Typography>{`${waitingForDelivery} ${formik.values.InvoicedQuantity._QuantityUnitCode}`}</Typography>
        </GridItem>
      </GridItem>
    )
    :
    <GridItem container item xs={12}>
      {t("app.components.StorageItemModal.allDelivered")}
    </GridItem>
  }

  const addNewDelivery = (storages: StorageItemState[]) => {
    setOpenAddDeliveryDialog(() => false);
    setLoading(() => true);
    // Save information of the new delivery
    const newDelivery: DeliveryState = {
      Amount: storages.reduce<number>((total, it) => total + it.Amount, 0),
      CreatedAt: storages[0].UpdatedAt
    };
    storageItem.Deliveries.push(newDelivery);
    // Update storages. If this product already exist in the same storage location,
    // add new delivery quantity to the same storage item. Else add a new storage item.
    const newStorageLocations: StorageItemState[] = [];
    storages.forEach(newStorage => {
      const matchingOldStorage = storageItem.Storages.find(oldStorage => oldStorage.LocationId === newStorage.LocationId);
      if (matchingOldStorage) {
        // StorageItem already has items in this location so add items from new delivery to this
        matchingOldStorage.Amount = matchingOldStorage.Amount + newStorage.Amount;
      }
      else {
        newStorageLocations.push(newStorage);
      }
    });
    storageItem.Storages.push(...newStorageLocations);
    // Finally, save storage item
    appContext.saveStorageItemsAsync([storageItem]).finally(() => setLoading(() => false));
  }

  const addNewUsage = (usage: UsageItemState) => {
    setOpenAddUsageDialog(() => false);
    setLoading(() => true);
    // Add new usage item
    storageItem.Usages.push(usage);
    // Reduce used amount from storage
    const storageLoc = storageItem.Storages.find(it => it.LocationId === usage.StorageLocationId);
    if (storageLoc) {
      storageLoc.Amount = storageLoc.Amount - usage.Amount;
      storageLoc.UpdatedAt = usage.UpdatedAt
      if (storageLoc.Amount <= 0) {
        storageItem.Storages = storageItem.Storages.filter(it => it.LocationId !== storageLoc.LocationId);
      }
    }
    appContext.saveStorageItemsAsync([storageItem]).finally(() => setLoading(() => false));
  }

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
    >
      <Card sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        background: tokens.colors.highlightLight2,
        padding: tokens.spacing.xs
      }}>
        <Typography variant="button">{storageItem.ArticleName}</Typography>
        <Grid container spacing={2}>
          <GridItem item xs={6}>
            <FormControl fullWidth>
              <Typography variant="caption">{t("app.models.InvoiceRow.InvoicedObjectID.title")}</Typography>
              <TextField
                fullWidth
                name={"InvoicedObjectID"}
                sx={{background: tokens.colors.whiteBase}}
                value={formik.values.InvoicedObjectID?.Value}
                size="small"
              />
            </FormControl>
          </GridItem>
          <GridItem item xs={6}>
            <FormControl fullWidth>
              <Typography variant="caption">{t("app.models.StorageItem.CreatedAt.title")}</Typography>
              <TextField
                fullWidth
                name={"CreatedAt"}
                sx={{background: tokens.colors.whiteBase}}
                value={formik.values.CreatedAt}
                size="small"
              />
            </FormControl>
          </GridItem>
          <GridItem container item xs={12}>
            <GridItem item xs={6}>
              <Typography>{`${t("app.components.StorageItemModal.invoicedAmount")}`}</Typography>
            </GridItem>
            <GridItem item xs={6}>
              <Typography>{`${formik.values.InvoicedQuantity.Value} ${formik.values.InvoicedQuantity._QuantityUnitCode}`}</Typography>
            </GridItem>
          </GridItem>
          <GridItem item xs={12} sx={{flexDirection: "column"}}>
            <Typography variant="caption" sx={{alignSelf: "flex-start"}}>{t("app.components.StorageItemModal.deliveryStatus")}</Typography>
            <Grid container>
              { getDelivered() }
              { formik.values.Deliveries.length > 0 &&
                formik.values.Deliveries.map((delivery, deliveryIndex) => 
                  <GridItem container item key={`delivery-${deliveryIndex}`} xs={12}>
                    <GridItem item xs={6}>
                      <Typography>{delivery.CreatedAt}</Typography>
                    </GridItem>
                    <GridItem item xs={6}>
                      <Typography>{`${delivery.Amount} ${formik.values.InvoicedQuantity._QuantityUnitCode}`}</Typography>
                    </GridItem>
                  </GridItem>
                )
              }
              { getWaitingForDelivery() > 0 && 
                <GridItem container item xs={12}>
                  <Button variant="contained" size="small" onClick={() => setOpenAddDeliveryDialog(() => true)}>
                    <Typography>{t("app.components.StorageItemModal.addDelivery")}</Typography>
                  </Button>
                </GridItem>
              }
            </Grid>
          </GridItem>
          <GridItem item xs={12} sx={{flexDirection: "column"}}>
            <Typography variant="caption" sx={{alignSelf: "flex-start"}}>{t("app.components.StorageItemModal.storageStatus")}</Typography>
            <Grid container>
              { formik.values.Storages.length > 0 ?
                formik.values.Storages.map((storage, storageIndex) => 
                  <GridItem container item key={`storage-${storageIndex}`} xs={12}>
                    <GridItem item xs={6}>
                      <Typography>{storage.LocationId}</Typography>
                    </GridItem>
                    <GridItem item xs={6}>
                      <Typography>{`${storage.Amount} ${formik.values.InvoicedQuantity._QuantityUnitCode}`}</Typography>
                    </GridItem>
                  </GridItem>
                ) :
                <GridItem container item xs={12}>
                  <Typography>{t("app.components.StorageItemModal.nothingInStorage")}</Typography>
                </GridItem>
              }
            </Grid>
          </GridItem>
          <GridItem item xs={12} sx={{flexDirection: "column"}}>
            <Typography variant="caption" sx={{alignSelf: "flex-start"}}>{t("app.components.StorageItemModal.usageStatus")}</Typography>
            <Grid container>
              { formik.values.Usages.length > 0 ?
                formik.values.Usages.map((usage, usageIndex) => 
                  <GridItem container item key={`usage-${usageIndex}`} xs={12}>
                    <GridItem item xs={6}>
                      <Typography>{usage.UsageLocationId}</Typography>
                    </GridItem>
                    <GridItem item xs={6}>
                      <Typography>{`${usage.Amount} ${formik.values.InvoicedQuantity._QuantityUnitCode}`}</Typography>
                    </GridItem>
                  </GridItem>
                ) :
                <GridItem container item xs={12}>
                  <Typography>{t("app.components.StorageItemModal.notUsed")}</Typography>
                </GridItem>
              }
            </Grid>
          </GridItem>
          <GridItem item xs={12} sx={{flexDirection: "row-reverse", justifyContent: "space-between"}}>
            <Button variant="contained" size="small" color="error" onClick={handleClose}>
              <Typography>{t("app.components.StorageItemModal.closeModalBtn")}</Typography>
            </Button>
            { StorageItemUtils.getQuantityInStorages(storageItem) > 0 && 
              <Button variant="contained" size="small" color="success" onClick={() => setOpenAddUsageDialog(() => true)}>
                <Typography>{t("app.components.StorageItemModal.addUsageBtn")}</Typography>
              </Button>
            }
          </GridItem>
        </Grid>
        { openAddDeliveryDialog &&
          <AddDeliveryDialog
            open={openAddDeliveryDialog}
            storageItem={formik.values}
            confirmAction={addNewDelivery}
            cancelAction={() => setOpenAddDeliveryDialog(() => false)}
          />
        }
        { openAddUsageDialog &&
          <AddUsageDialog
            open={openAddUsageDialog}
            storageItem={formik.values}
            confirmAction={addNewUsage}
            cancelAction={() => setOpenAddUsageDialog(() => false)}
          />
        }
        <LoadingModal isOpen={loading} overLay={2000} contentLabel={"Saving"} message={t("common.savingState")}/>
      </Card>
    </Modal>
  );
}

export default StorageItemModal;