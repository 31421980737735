import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { StorageItem, StorageItemState, Storages } from '../../../models/StorageModels';
import { getIn, useFormik } from 'formik';
import * as StorageItemUtils from '../../../utils/StorageItemUtils';
import { toDateTimeString } from '../../../utils/dateUtils';
import { GridItem } from '../../common/CommonStyles';
import { StorageItemStateSchema } from '../../../models/StorageModelsSchemas';

interface Props {
  open: boolean,
  storageItem: StorageItem,
  confirmAction: (storages: StorageItemState[]) => void,
  cancelAction: () => void
}

const AddDeliveryDialog: React.FC<Props> = ({open, storageItem, confirmAction, cancelAction}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    confirmAction(formik.values.storages);
  }

  const formik = useFormik({
    initialValues: { 
      storages: [
        {
          Amount: StorageItemUtils.getWaitingForDeliveryQuantity(storageItem),
          LocationId: "",
          UpdatedAt: toDateTimeString(new Date())
        }
      ] 
    },
    validationSchema: Yup.object({
      storages: Yup.array().of(StorageItemStateSchema).test("sum", "waitingForDeliveryAmountError", ( (rows = [], ctx) => {
        const total = rows.reduce((total, row) => total + row.Amount, 0);
        return total <= StorageItemUtils.getWaitingForDeliveryQuantity(storageItem);
      })).required('Required'),
    }),
    onSubmit: handleSubmit
  });

  const getErrorMessage = (errorKey?: string) => {
    if (errorKey === "minValueError") {
      return t("common.validation.minValueError") + "1";
    }
    if (errorKey === "maxValueError") {
      return t("common.validation.maxValueError") + StorageItemUtils.getWaitingForDeliveryQuantity(storageItem);
    }
    if (errorKey === "waitingForDeliveryAmountError") {
      return t("app.components.AddDeliveryDialog.waitingForDeliveryAmountError") + StorageItemUtils.getWaitingForDeliveryQuantity(storageItem);
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  const addStorageLocation = () => {
    const storages = formik.values.storages;
    const existingAmount = storages.reduce<number>((total, it) => total + it.Amount, 0);
    const waitingForDeliveryQuantity = StorageItemUtils.getWaitingForDeliveryQuantity(storageItem);
    const remainingAmount = existingAmount <= waitingForDeliveryQuantity ? waitingForDeliveryQuantity - existingAmount : 0;
    storages.push({
      Amount: remainingAmount,
      LocationId: "",
      UpdatedAt: storages[0].UpdatedAt
    });
    formik.setFieldValue("storages", storages);
  }

  const deleteStorageLocation = (storageIndex: number) => {
    const storages = formik.values.storages;
    storages.splice(storageIndex, 1);
    formik.setFieldValue("storages", storages);
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>
          {t("app.components.AddDeliveryDialog.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{marginBottom: tokens.spacing.xs}}>
            {formik.values.storages[0].UpdatedAt}
          </DialogContentText>
          <DialogContentText>
            {t("app.components.AddDeliveryDialog.helptext")}
          </DialogContentText>
          { formik.values.storages.map((storage, storageIndex) => {
            const locationIdFieldName = `storages[${storageIndex}].LocationId`;
            const amountFieldName = `storages[${storageIndex}].Amount`;
            return (
            <Card key={`storage-${storageIndex}`} sx={{padding: tokens.spacing.xs, marginBottom: tokens.spacing.xxs, background: tokens.colors.highlightLight2}}>
              <Grid container>
                <Grid item xs={12}>
                  <FormControl>
                    <Typography variant="caption">{`${t("app.components.AddDeliveryDialog.amount")} - ${storageItem.InvoicedQuantity._QuantityUnitCode}`}</Typography>
                    <TextField
                      name={amountFieldName}
                      type="number"
                      sx={{background: tokens.colors.whiteBase}}
                      value={storage.Amount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={Boolean(getIn(formik.touched, amountFieldName)) && Boolean(getIn(formik.errors, amountFieldName))}
                      size="small"
                    />
                    { Boolean(getIn(formik.touched, amountFieldName)) && Boolean(getIn(formik.errors, amountFieldName)) &&
                      <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(getIn(formik.errors, amountFieldName))}</FormHelperText> 
                    }
                    { typeof(formik.errors.storages) === "string" &&
                      <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(formik.errors.storages)}</FormHelperText> 
                    }
                  </FormControl>
                  
                </Grid>
                <GridItem item xs={12}>
                  <FormControl>
                    <RadioGroup
                      name={locationIdFieldName}
                      value={storage.LocationId ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      sx={{justifyContent: "flex-end"}}
                    >
                      { Storages.map((storage) => (
                        <FormControlLabel key={`storage-radio-${storage.id}`} value={storage.id} control={<Radio size="small" sx={{padding: tokens.spacing.xxs}} />} label={`${storage.id} - ${storage.name}`} sx={{margin: 0}}/>
                      ))}
                    </RadioGroup>
                    { Boolean(getIn(formik.touched, locationIdFieldName)) && Boolean(getIn(formik.errors, locationIdFieldName)) && 
                      <FormHelperText sx={{color: tokens.colors.alertBase}}>{t(`common.validation.${getIn(formik.errors, locationIdFieldName)}`)}</FormHelperText> 
                    }
                  </FormControl>
                </GridItem>
                { storageIndex > 0 &&
                  <GridItem item xs={12} sx={{flexDirection: "row-reverse"}}>
                    <Button color="error" onClick={() => deleteStorageLocation(storageIndex)}>
                      {t("common.actions.delete.title")}
                    </Button>
                  </GridItem>
                }
              </Grid>
            </Card>
            )}
          )}
          <Button variant="text" size="small" onClick={() => addStorageLocation()}>
            <Typography>{t("app.components.SelectStorageView.addStorage")}</Typography>
          </Button>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button variant="contained" onClick={cancelAction}>{t("common.actions.cancel.title")}</Button>
          <Button variant="contained" type="submit">{t("common.actions.yes.title")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddDeliveryDialog;