import React, { useEffect, useState, PropsWithChildren, useContext } from "react";
import log from "loglevel";
import _ from "lodash";
import * as StorageApi from "../api/StorageApi";
import { StorageItem } from "../models/StorageModels";

interface AppState {
  isLoadingApp: boolean,
  loggedIn?: boolean,
  showDemoInfo: boolean,
  storageItems: StorageItem[]
}

export type AppStateType = AppState & {
  // Functions
  setShowDemoInfo: (value: boolean) => void,
  getStorageItemsAsync: () => Promise<StorageItem[]>,
  saveStorageItemsAsync: (storageItems: StorageItem[]) => Promise<StorageItem[]>,
  refreshAppState: () => Promise<void>,
  resetAppState: () => Promise<void>,
  logout: () => Promise<void>,
}

export const DefaultAppState: AppState = {
  isLoadingApp: true,
  loggedIn: false,
  showDemoInfo: false,
  storageItems: []
}

export const DefaultAppStateContext: AppStateType = {
  ...DefaultAppState,
  setShowDemoInfo: (value: boolean) => {},
  getStorageItemsAsync: () => new Promise<StorageItem[]>(resolve => resolve([])),
  saveStorageItemsAsync: (storageItems: StorageItem[]) => new Promise<StorageItem[]>(resolve => resolve([])),
  refreshAppState: () => new Promise<void>(resolve => resolve()),
  resetAppState: () => new Promise<void>(resolve => resolve()),
  logout: () => new Promise<void>(resolve => resolve()),
}

// AppContext with default values. AppContextProvider replaces defaults with the real values.
export const AppStateContext = React.createContext<AppStateType>(DefaultAppStateContext);

enum PromiseTypes {
  STORAGE_ITEMS = "storageItems"
}

/**
 * Methods that have a comment "public" are accessible outside of the AppContextProvider.
 * Methods that have a comment "private" are for internal use of the AppContextProvider only.
 */
const AppContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [appState, setAppState] = useState<AppState>(DefaultAppState);
  // Contains ongoing request promises. To update state use methods addPromise and removePromise
  const [promises, setPromises] = useState<{[type: string]: Promise<any>}>({});
  const logger = log.getLogger(AppContextProvider.name);

  // private
  const addPromise = (type: string, promise: Promise<any>) => {
    setPromises(oldState => ({...oldState, [type]: promise}));
  }

  // private
  const removePromise = (type: string) => {
    setPromises(oldState => _.omit(oldState, type));
  }

  // Run this useEffect only once when app loads
  useEffect(() => {
    loadAppStateAsync();
  }, []);

  useEffect(() => {
    if (appState.loggedIn) {
      getStorageItemsAsync();
    }
  }, [appState.loggedIn]);
  
  // private
  const loadAppStateAsync = async (): Promise<void> => {
    logger.debug("ACP loadAppStateAsync");
    setAppState(appState => ({...appState, isLoadingApp: true}));
    StorageApi.GetCurrentDemoAccount()
    .then(res => {
      setAppState(appState => ({...appState, loggedIn: true, isLoadingApp: false}));
    })
    .catch(err => {
      if (err !== "Not logged in") {
        logger.error("Error", err);
      }
      console.log("loadAppStateAsync failed")
      setAppState(appState => ({...appState, loggedIn: false, isLoadingApp: false}));
    });
  }

  // public
  const setShowDemoInfo = async (value: boolean) => {
    setAppState(oldState => ({...oldState, showDemoInfo: value}));
  }

  // public
  const getStorageItemsAsync = async (): Promise<StorageItem[]> => {

    const fetchStorageItems = async (): Promise<StorageItem[]> => {
      const promise = StorageApi.GetStorageItems()
      .then(res => {
        if (res.data) {
          setAppState(oldState => ({...oldState, storageItems: res.data}));
          return res.data;
        }
        return [];
      })
      .catch(err => {
        logger.error("Error while fetching storageItems", err);
        return [];
      })
      .finally(() => removePromise(PromiseTypes.STORAGE_ITEMS));
      addPromise(PromiseTypes.STORAGE_ITEMS, promise);
      return promise;
    }

    return promises[PromiseTypes.STORAGE_ITEMS] ?? fetchStorageItems();
  }

  // Post new/updated items to backend. Backend sends saved items as a response.
  // Updated saved items into context state: update existing items and add new items.
  const saveStorageItemsAsync = async (storageItems: StorageItem[]): Promise<StorageItem[]> => {
    const promise = StorageApi.PostStorageItems(storageItems)
    .then(res => {
      if (res.data) {
        const newItems = res.data;
        setAppState(oldState => {
          const storageItems = oldState.storageItems;
          newItems.forEach(newItem => {
            const existingItemIndex = storageItems.findIndex(it => it.OmaXId === newItem.OmaXId);
            if (existingItemIndex > -1) {
              storageItems[existingItemIndex] = newItem;
            }
            else {
              storageItems.push(newItem);
            }
          })
          return {...oldState, storageItems};
        });
        return res.data;
      }
      return [];
    })
    .catch(err => {
      logger.error("Error while saving storageItems", err);
      return [];
    });
    return promise;
  }

  const refreshAppState = async (): Promise<void> => {
    await loadAppStateAsync();
  }

  const resetAppState = async (): Promise<void> => {
    setAppState(() => DefaultAppState);
  }

  const logout = async (): Promise<void> => {
    StorageApi.DeleteDemoAccount()
    .then(() => {
      return resetAppState();
    })
    .then(() => {
      return loadAppStateAsync();
    });
  }

  return (
    <AppStateContext.Provider value={{
      ...appState,
      setShowDemoInfo,
      getStorageItemsAsync,
      saveStorageItemsAsync,
      refreshAppState,
      resetAppState,
      logout
    }}>
      {children}
    </AppStateContext.Provider>
  );
}

export const useAppStateContext = () => {
  const appContext = useContext(AppStateContext);
  if (appContext === undefined) {
    throw new Error("useAppStateContext must be used within a AppContextProvider");
  }
  return appContext;
}

export default AppContextProvider;
