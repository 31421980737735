/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import React from 'react';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ButtonLink } from "./InputStyles";
import { useTranslation } from 'react-i18next';
import UserMenu from './UserMenu';
import { useAppStateContext } from '../../state/AppStateContext';
import DemoInfo from '../DemoInfo';

const AppHeader = styled.header`
  display: flex;
  font-weight: 600;
`

const AppTitle = styled.div`
  display: flex;
  color: ${tokens.colors.whiteBase};
  font-size: 28px;
  flex-grow: 1;
  align-items: center;
`

export const SelectLanguage = styled.div`
  display: flex;
  text-align: right;
  line-height: 1;
  margin-left: ${tokens.spacing.xs};
`

const Header: React.FC = () => {
  const { t, i18n } = useTranslation();
  const appContext = useAppStateContext();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
      <AppHeader>
        <AppTitle>
          {t("app.header.title")}
        </AppTitle>
        { appContext.loggedIn && <DemoInfo/>}
        { appContext.loggedIn && <UserMenu/>}
        { !appContext.loggedIn && 
          <SelectLanguage>
            <ButtonLink
              style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`, color: tokens.colors.whiteBase}}
              onClick={() => changeLanguage('fi') }
            >
              FI
            </ButtonLink>
            <ButtonLink
              style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`, color: tokens.colors.whiteBase}}
              onClick={() => changeLanguage('en') }
            >
              EN
            </ButtonLink>
          </SelectLanguage>
        }
      </AppHeader>
  );
};

export default Header;
