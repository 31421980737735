/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { PropsWithChildren } from "react"
import { Link } from 'react-router-dom';
import { Heading, suomifiDesignTokens as tokens } from 'suomifi-ui-components';
import Breadcrumbs, { BreadcrumbItem } from './Breadcrumbs'
import { FlexColTight, PageHeader, PageHeadingWrapper, panelWithShadowCss } from './CommonStyles';

interface Props {
  title?: string,
  breadcrumbs?: BreadcrumbItem[],
  titleAction?: React.ReactElement,
  additionalInfo?: {
    text: string,
    path: string
  }
}

const Page: React.FC<PropsWithChildren<Props>> = props => {

  const styles = css({
    paddingLeft: tokens.spacing.m,
    paddingRight: tokens.spacing.m,
    paddingTop: tokens.spacing.xxs,
    paddingBottom: tokens.spacing.m,
    boxSizing: 'border-box',
    margin: '0 auto',
    width: '100%',
    maxWidth: '1200px',
    '@media (min-width: 992px)': {
      paddingLeft: tokens.spacing.xxl,
      paddingRight: tokens.spacing.xxl,
      paddingTop: props.breadcrumbs ? tokens.spacing.xs : tokens.spacing.xxl,
      paddingBottom: tokens.spacing.xxl,
    },
    '@media (max-width: 600px)': {
      paddingLeft: tokens.spacing.xxs,
      paddingRight: tokens.spacing.xxs
    }
  });
  
  return (
    <div css={styles}>
      { props.breadcrumbs && <Breadcrumbs items={props.breadcrumbs} />}
      <FlexColTight css={panelWithShadowCss}>
        {props.title && 
          <PageHeader>
            <PageHeadingWrapper>
              <Heading variant="h2" smallScreen>
                {props.title}
              </Heading>
            </PageHeadingWrapper>
            {props.titleAction}
          </PageHeader>
        }
        {props.children}

        { !props.additionalInfo ? 
          null: 
          <div style={{ marginTop: "auto" }}>
            <Link to={props.additionalInfo.path}>
              <span style={{ color: tokens.colors.accentBase }}>&rsaquo; </span>
              {props.additionalInfo.text}
            </Link>
          </div>
        }
      </FlexColTight>
    </div>
  );
}

export default Page;