import axios, { AxiosResponse } from 'axios';

const apiRootUrl = '/test-invoices'

const api = axios.create({
  baseURL: apiRootUrl
});

export async function GetFile(file: string): Promise<AxiosResponse<any>> {
  return api.get(`/${file}`);
}