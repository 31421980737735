import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { StorageItem, Storages, UsageItemState, UsageLocations } from '../../../models/StorageModels';
import { useFormik } from 'formik';
import * as StorageItemUtils from '../../../utils/StorageItemUtils';
import { toDateTimeString } from '../../../utils/dateUtils';
import { GridItem } from '../../common/CommonStyles';

interface Props {
  open: boolean,
  storageItem: StorageItem,
  confirmAction: (usage: UsageItemState) => void,
  cancelAction: () => void
}

const AddUsageDialog: React.FC<Props> = ({open, storageItem, confirmAction, cancelAction}) => {
  const { t } = useTranslation();

  const handleSubmit = () => {
    confirmAction({
      Amount: parseInt(formik.values.Amount),
      StorageLocationId: formik.values.StorageLocationId,
      UsageLocationId: formik.values.UsageLocationId,
      UpdatedAt: toDateTimeString(new Date())
    });
  }

  const formik = useFormik({
    initialValues: { 
      Amount: "",
      StorageLocationId: storageItem.Storages.length === 1 ? storageItem.Storages[0].LocationId : "",
      UsageLocationId: ""
    },
    validationSchema: Yup.object({
      Amount: Yup.number().integer("integerError").min(1, "minValueError").test("max", "maxValueError", ( (amount, ctx) => {
        if (!amount) {
          return true;
        }
        if (ctx.parent.StorageLocationId) {
          // Selected storage always exists because the user can select only from the existing storage locations
          const selectedStorage = storageItem.Storages.find(it => it.LocationId === ctx.parent.StorageLocationId);
          return amount <= selectedStorage!.Amount;
        }
        return true;
      })).required('Required'),
      StorageLocationId:          Yup.string().required('Required'),
      UsageLocationId:            Yup.string().required('Required')
    }),
    onSubmit: handleSubmit
  });

  const getUsageLocationOptions = () => {
    const emptyOption = [<option key={"0"} value={""}>{t("app.components.AddUsageDialog.selectLocation")}</option>]
    return emptyOption.concat(UsageLocations.map((option) => (
      <option key={option.id} value={option.id}>
        {`${option.id} - ${option.name}`}
      </option>
    )));
  }

  const getErrorMessage = (errorKey?: string) => {
    if (errorKey === "minValueError") {
      return t("common.validation.minValueError") + "1";
    }
    if (errorKey === "maxValueError") {
      return t("common.validation.maxValueError") + storageItem.Storages.find(it => it.LocationId === formik.values.StorageLocationId)?.Amount;
    }
    if (errorKey === "waitingForDeliveryAmountError") {
      return t("app.components.AddUsageDialog.waitingForDeliveryAmountError") + StorageItemUtils.getWaitingForDeliveryQuantity(storageItem);
    }
    return errorKey ? t(`common.validation.${errorKey}`) : undefined;
  }

  return (
    <Dialog open={open}>
      <form onSubmit={formik.handleSubmit} autoComplete="off">
        <DialogTitle>
          {t("app.components.AddUsageDialog.title")}
        </DialogTitle>
        <DialogContent>
          <Card sx={{padding: tokens.spacing.xs, marginBottom: tokens.spacing.xxs, background: tokens.colors.highlightLight2}}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl>
                  <Typography variant="caption">{`${t("app.components.AddUsageDialog.amount")} - ${storageItem.InvoicedQuantity._QuantityUnitCode}`}</Typography>
                  <TextField
                    name={"Amount"}
                    type="number"
                    sx={{background: tokens.colors.whiteBase}}
                    value={formik.values.Amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.Amount) && Boolean(formik.errors.Amount)}
                    size="small"
                  />
                  { Boolean(formik.touched.Amount) && Boolean(formik.errors.Amount) &&
                    <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(formik.errors.Amount)}</FormHelperText> 
                  }
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <Typography variant="caption">{`${t("app.components.AddUsageDialog.usageLocation")}`}</Typography>
                  <TextField
                    select
                    name={"UsageLocationId"}
                    sx={{background: tokens.colors.whiteBase}}
                    value={formik.values.UsageLocationId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={Boolean(formik.touched.UsageLocationId) && Boolean(formik.errors.UsageLocationId)}
                    size="small"
                    SelectProps={{
                      native: true,
                    }}
                  >
                    {getUsageLocationOptions()}
                  </TextField>
                  { Boolean(formik.touched.UsageLocationId) && Boolean(formik.errors.UsageLocationId) &&
                    <FormHelperText sx={{color: tokens.colors.alertBase}}>{getErrorMessage(formik.errors.UsageLocationId)}</FormHelperText> 
                  }
                </FormControl>
              </Grid>
              <GridItem item xs={12}>
                <FormControl>
                  <Typography variant="caption">{`${t("app.components.AddUsageDialog.storageLocation")}`}</Typography>
                  <RadioGroup
                    name={"StorageLocationId"}
                    value={formik.values.StorageLocationId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    sx={{justifyContent: "flex-end"}}
                  >
                    { storageItem.Storages.map((storageLocation) => {
                      const storage = Storages.find(it => it.id === storageLocation.LocationId);
                      return <FormControlLabel key={`storage-radio-${storage?.id}`} value={storage?.id} control={<Radio size="small" sx={{padding: tokens.spacing.xxs}} />} label={`${storage?.id} - ${storage?.name}`} sx={{margin: 0}}/>
                    })}
                  </RadioGroup>
                  { Boolean(formik.touched.StorageLocationId) && Boolean(formik.errors.StorageLocationId) && 
                    <FormHelperText sx={{color: tokens.colors.alertBase}}>{t(`common.validation.${formik.errors.StorageLocationId}`)}</FormHelperText> 
                  }
                </FormControl>
              </GridItem>
            </Grid>
          </Card>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-between"}}>
          <Button variant="contained" onClick={cancelAction}>{t("common.actions.cancel.title")}</Button>
          <Button variant="contained" type="submit">{t("common.actions.yes.title")}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default AddUsageDialog;