import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from "react-modal";
import './index.css';
import './fonts.css';
import App from './App';
import './i18n';
import log, { LogLevel } from 'loglevel';

ReactModal.setAppElement('#root');

const logLevel = process.env.REACT_APP_LOG_LEVEL ? (process.env.REACT_APP_LOG_LEVEL as keyof LogLevel) : "error";
console.log("Set log level to", logLevel);
log.setLevel(logLevel);

const root = createRoot(document.getElementById('root')!);
root.render(<App/>);
