/** @jsxImportSource @emotion/react */
import React, { Suspense } from 'react';
import { css, Global } from '@emotion/react';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import styled from '@emotion/styled'
import { suomifiDesignTokens as tokens, SuomifiThemeProvider } from 'suomifi-ui-components';
import { ContentContainer, TopNavigationBackground } from './components/common/CommonStyles';
import Header from './components/common/Header';
import AppContextProvider, { AppStateContext } from './state/AppStateContext';
import LoginView from './components/pages/LoginView';
import PageNotFoundView from './components/pages/PageNotFoundView';
import { createTheme, ThemeProvider } from '@mui/material';
import MobileAppMenu from './components/MobileAppMenu';
import StorageListView from './components/pages/StorageList/StorageListView';
import InvoicesView from './components/pages/InvoiceList/InvoicesView';
import ProfitView from './components/pages/Profit/ProfitView';
import ProfitListView from './components/pages/Profit/ProfitListView';

const CustomHeaderBackground = styled.div`
  background: ${tokens.colors.highlightBase};
  border-top: 4px solid ${tokens.colors.brandBase};
  padding: ${tokens.spacing.m} 0;
`;

const App: React.FC = () => {

  let muiTheme = createTheme({
    palette: {
      primary: {
        main: tokens.colors.highlightBase,
      },
      secondary: {
        main: tokens.colors.highlightBase,
      },
    },
  });

  const suomiFiTheme = {
    colors: {
      brandBase: tokens.colors.whiteBase
    }
  };

  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={muiTheme}>
        <SuomifiThemeProvider theme={suomiFiTheme}>
          <AppContextProvider>
            <Global styles={css`
              body {
                background-color: ${tokens.colors.depthLight3};
                font-family: ${tokens.values.typography.bodyText.fontFamily};
                font-size: ${tokens.values.typography.bodyText.fontSize.value}${tokens.values.typography.bodyText.fontSize.unit};
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                line-height: ${tokens.values.typography.bodyText.lineHeight.value}${tokens.values.typography.bodyText.lineHeight.unit};
                margin: 0;
              }
              a {
                color: ${tokens.colors.highlightBase};
                text-decoration: none; 
              }
              a:visited {
                color: ${tokens.colors.accentTertiaryDark1}
              }
              code {
                white-space: pre-wrap !important;
              }
              label {
                font-weight: ${tokens.values.typography.bodySemiBold.fontWeight}
              } 
              hr {
                border: 0;
                border-bottom: 1px solid ${tokens.colors.depthLight3};
                margin: ${tokens.spacing.m} 0;
              }
              ul, ol {
                margin: 0;
                padding: 0;
                @media (max-width: 1200px) {
                  list-style-position: inside;              
                }
              }
              li {
                margin: 0;
                padding: 0;            
              }
              p {
                margin: ${tokens.spacing.m} 0;
              }
            `}
            />
            <Router>
              <CustomHeaderBackground>
                <ContentContainer>
                  <Header/>
                </ContentContainer>
              </CustomHeaderBackground>

              <TopNavigationBackground>
                <MobileAppMenu/>
              </TopNavigationBackground>

              <AppStateContext.Consumer>
                {state => !state.isLoadingApp && !state.loggedIn ?
                  <Routes>
                    <Route path="/login" element={<LoginView/>}/>
                    <Route path="*" element={<Navigate to={"/login"}/>}/>
                  </Routes> :
                  <Routes>
                    { RoutePaths.map((it, i) => 
                      <Route key={`route-${i}`} path={it.to} element={it.element}/>
                    )}
                    <Route path="/login" element={<Navigate to={"/"}/>}/>
                    <Route path="/" element={<Navigate to={"/storage"}/>}/>
                    <Route path="/404" element={<PageNotFoundView/>}/>
                    <Route path="*" element={<Navigate to={"/404"}/>}/>
                  </Routes>
                }
              </AppStateContext.Consumer>
            </Router>
          </AppContextProvider>
        </SuomifiThemeProvider>
      </ThemeProvider>
    </Suspense>
  )
};

export interface RoutePathProps {
  to: string;
  label: string;
  element: React.ReactNode | null;
  visibleInNav: boolean;
}

export const RoutePaths: RoutePathProps[] = [
  { to: "/invoices", label: "invoices", element: <InvoicesView/>, visibleInNav: true},
  { to: "/storage", label: "storage", element: <StorageListView/>, visibleInNav: true},
  { to: "/profit", label: "profit", element: <ProfitListView/>, visibleInNav: true},
  { to: "/profit/:referent", label: "profit", element: <ProfitView/>, visibleInNav: false}
]

export default App;