import * as Yup from 'yup';
import { InvoiceRowSchema } from './FinvoiceModelsSchemas';
import * as StorageModels from './StorageModels';

export const StorageLocationSchema: Yup.ObjectSchema<StorageModels.StorageLocation> = Yup.object({
  id:                         Yup.string().required('Required'),
  name:                       Yup.string().required('Required'),
  type:                       Yup.mixed<StorageModels.StorageTypeEnum>().required('Required'),
  description:                Yup.string().required('Required')
});

export const DeliveryStateSchema: Yup.ObjectSchema<StorageModels.DeliveryState> = Yup.object({
  Amount:                     Yup.number().integer("integerError").min(1, "minValueError").required('Required'),
  CreatedAt:                  Yup.string().required('Required')
});

export const StorageItemStateSchema: Yup.ObjectSchema<StorageModels.StorageItemState> = Yup.object({
  Amount:                     Yup.number().integer("integerError").min(1, "minValueError").required('Required'),
  LocationId:                 Yup.string().required('Required'),
  UpdatedAt:                  Yup.string().required('Required')
});

export const UsageItemStateSchema: Yup.ObjectSchema<StorageModels.UsageItemState> = Yup.object({
  Amount:                     Yup.number().integer("integerError").min(1, "minValueError").required('Required'),
  StorageLocationId:          Yup.string().required('Required'),
  UsageLocationId:            Yup.string().required('Required'),
  UpdatedAt:                  Yup.string().required('Required')
});

export const StorageItemSchema: Yup.ObjectSchema<StorageModels.StorageItem> = InvoiceRowSchema.shape({
  OmaXId:                     Yup.string(),
  Deliveries:                 Yup.array().of(DeliveryStateSchema).required('Required'),
  Storages:                   Yup.array().of(StorageItemStateSchema).test("sum", "storageAmountError", ( (rows = [], ctx) => {
                                const total = rows.reduce((total, row) => total + row.Amount, 0);
                                return validateStorageAmounts(total, ctx.parent);
                              })).required('Required'),
  Usages:                     Yup.array().of(UsageItemStateSchema).required('Required'),
  InvoiceNumber:              Yup.string().required('Required'),
  InvoiceRowNumber:           Yup.number().required('Required'),
  CreatedAt:                  Yup.string().required('Required')
});

const validateStorageAmounts = (amountInStorages: number, storageItem: StorageModels.StorageItem): boolean => {
  return storageItem.Storages.length === 0 || amountInStorages === parseInt(storageItem.InvoicedQuantity.Value);
}