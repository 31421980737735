import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Box, Button, ButtonBase, Card, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TextField, Typography } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import Page from '../../common/Page';
import { UsageLocation, UsageLocations } from '../../../models/StorageModels';
import TablePaginationActions from '../../common/TablePaginationActions';
import { useAppStateContext } from '../../../state/AppStateContext';

const TableCellCustom = styled(TableCell)`
  padding: ${tokens.spacing.xs} 0px
`

const ProfitListView: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [showVipuDialog, setShowVipuDialog] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [searchFilters, setSearchFilters] = useState<string[]>([]);
  const [searchResults, setSearchResults] = useState<UsageLocation[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  
  const addKeyword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (keyword && searchFilters.findIndex(it =>it === keyword) < 0) {
      setSearchFilters(old => {
        const filters = [...old];
        filters.push(keyword);
        return filters;
      });
      setKeyword(() => "");
    }
  }

  const deleteKeyword = (index: number) => {
    setSearchFilters(old => {
      const filters = [...old];
      filters.splice(index, 1);
      return filters;
    });
  }

  useEffect(() => {
    let filteredItems = UsageLocations.filter(location => {
      // Storage item must match with all filters to be added to search results
      const res = searchFilters.reduce((match, keyword) => {
        const keywordLower = keyword.toLowerCase();
        // If there is at least one filter that does not match, discard item from search results
        if (!match) return false;
        // It is enough that one of the following is true
        const idMatch = location.id.toLowerCase().search(keywordLower) >= 0;
        if (idMatch) {
          return true;
        }
        const nameMatch = location.name.toLowerCase().search(keywordLower) >= 0;
        if (nameMatch) {
          return true;
        }
        const productMatch = appContext.storageItems.filter(item => {
          const usageLocationMatch = item.Usages.findIndex(usage => usage.UsageLocationId === location.id) >= 0;
          // Skip product it is not used in current location
          if (!usageLocationMatch) return false;
          const batchNumberMatch = item.InvoicedObjectID!.Value.toLowerCase().search(keywordLower) >= 0;
          if (batchNumberMatch) {
            return true;
          }
          const articleNameMatch = item.ArticleName.toLowerCase().search(keywordLower) >= 0;
          if (articleNameMatch) {
            return true;
          }
          const articleIdentifierMatch = item.ArticleIdentifier.toLowerCase().search(keywordLower) >= 0;
          if (articleIdentifierMatch) {
            return true;
          }
          return false;
        });
        if (productMatch.length > 0) {
          return true;
        }
        return false;
      }, true);
      return res;
    });
    setSearchResults(() => filteredItems);
  }, [searchFilters]);

  const navigateToLocation = (locationId: string) => {
    navigate("/profit/" + locationId);
  }

  return (
    <Page>
      <form onSubmit={(e) => addKeyword(e)} autoComplete="off">
        <Box sx={{display: "flex"}}>
          <TextField
            label={t("app.components.ProfitListView.searchByKeyword")}
            value={keyword}
            onChange={e => setKeyword(e.target.value)}
            size="small"
            sx={{flexGrow: 1}}
          />
          <IconButton color="primary" type="submit">
            <SearchOutlinedIcon/>
          </IconButton>
        </Box>
      </form>
      <Grid container sx={{margin: 0, py: tokens.spacing.xxs}}>
        <Grid item xs component={Typography} variant="body2" color={tokens.colors.brandBase} >{t("app.components.ProfitListView.searchHelptext")}</Grid>
        <Grid item xs={3} component={Button} variant="contained" size="small" onClick={() => setShowVipuDialog(() => true)}>{t("app.components.ProfitListView.updateFromVipuBtn")}</Grid>
      </Grid>
      { searchFilters.length > 0 && 
        <Box sx={{marginTop: tokens.spacing.xs}}>
          { searchFilters.map((filter, filterIndex) => 
            <Chip key={`filter-${filterIndex}`} label={filter} onDelete={() => deleteKeyword(filterIndex)} sx={{marginRight: tokens.spacing.xxs}}/>
          )}
        </Box>
      }
      <TableContainer component={Card} sx={{width: "100%"}}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCellCustom>{t("app.components.ProfitListView.locationId")}</TableCellCustom>
              <TableCellCustom align="center">{t("app.components.ProfitListView.locationName")}</TableCellCustom>
            </TableRow>
          </TableHead>
          <TableBody>
            { (rowsPerPage > 0
                ? searchResults.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : searchResults
              ).map((item, itemIndex) => (
              <ButtonBase
                key={itemIndex}
                component={TableRow}
                onClick={() => navigateToLocation(item.id)}
                sx={{display: "table-row", background: (itemIndex % 2 ? tokens.colors.depthLight2 : tokens.colors.depthLight3)}}
                >
                <TableCellCustom component="th" scope="row">{item.id}</TableCellCustom>
                <TableCellCustom align="center">{item.name}</TableCellCustom>
              </ButtonBase>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10]}
                count={searchResults.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog open={showVipuDialog} onClose={() => setShowVipuDialog(() => false)}>
        <DialogTitle>{t("app.components.ProfitListView.updateFromVipuBtn")}</DialogTitle>
        <DialogContent>
          {t("app.components.ProfitListView.updateFromVipuDialogContent")}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowVipuDialog(() => false)} autoFocus>
            {t("common.actions.close.title")}
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
}

export default ProfitListView;