import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled from '@emotion/styled';
import { Box, Container, Grid, List, ListItem, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from '../../../state/AppStateContext';
import Page from '../../common/Page';
import { ArticleProfits, StorageItem, UsageItemState, UsageLocation, UsageLocations } from '../../../models/StorageModels';
import { toDateString } from '../../../utils/dateUtils';
import { AmountCurrencyIdentifier } from '../../../models/FinvoiceModels';

const TableCellTight = styled(TableCell)`
  padding: 0;
  border-bottom: none;
`

interface Usage {
  usageItem: UsageItemState,
  storageItem: StorageItem
}

interface Profit {
  storageItem: StorageItem,
  quantity: number,
  price: string,
  profitEuro: string,
  profitPercentage: string
}

const ProfitView: React.FC = () => {
  const navigate = useNavigate();
  const { referent } = useParams();
  const { t } = useTranslation();
  const appContext = useAppStateContext();
  const [location, setLocation] = useState<UsageLocation|undefined>(undefined);
  const [usageItems, setUsageItems] = useState<Usage[]>([]);
  const [profitItems, setProfitItems] = useState<Profit[]>([]);
  const [selectedTab, setSelectedTab] = useState<number>(1);

  useEffect(() => {
    const matchingLocation = UsageLocations.find(it => it.id === referent);
    if (!matchingLocation) {
      navigate("/profit");
    }
    else {
      setLocation(() => matchingLocation);
      const storageItemsInLocation = appContext.storageItems.filter(it => it.Usages.filter(u => u.UsageLocationId === matchingLocation.id).length > 0);
      const usages = storageItemsInLocation.flatMap(item => item.Usages.filter(u => u.UsageLocationId === matchingLocation.id).map(u => ({usageItem: u, storageItem: item})));
      setUsageItems(() => usages);
      createProfitItems(matchingLocation.id);
    }
  }, [appContext.storageItems]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(() => newValue);
  };

  const fromDateTimeString = (dateString: string) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split(".");
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return toDateString(date);
  }

  const calculateTotalPrice = (amount: number, unitPrice: string) => {
    const unitPriceFloat = parseFloat(unitPrice) * 100;
    const unitPriceInt = parseInt(unitPriceFloat.toFixed(0));
    const totalPrice = amount * unitPriceInt / 100;
    const totalPriceString = totalPrice.toFixed(2);
    return totalPriceString.replace(".", ",");
  }

  const createProfitItems = (locationId: string) => {
    let profits: Profit[] = [];
    const storageItemsInLocation = appContext.storageItems.filter(it => it.Usages.filter(u => u.UsageLocationId === locationId).length > 0);
    storageItemsInLocation.forEach(storageItem => {
      const profitFactor = ArticleProfits.find(it => it.articleIdentifier === storageItem.ArticleIdentifier);
      if (profitFactor) {
        const quantity = storageItem.Usages.filter(u => u.UsageLocationId === locationId).reduce((total, current) => total + current.Amount, 0);
        const area = quantity / profitFactor.sowingRate; // m2
        const harvestExpectation = Math.round(area * profitFactor.harvestExpectation); // kg
        const price = harvestExpectation * profitFactor.price / 1000;
        const cost = parseInt(storageItem.UnitPriceAmount.Value) * quantity;
        const profitEuro = price - cost;
        const profitPercentage = profitEuro / price * 100;
        profits.push({
          storageItem,
          quantity: harvestExpectation,
          price: price.toFixed(2),
          profitEuro: profitEuro.toFixed(2),
          profitPercentage: profitPercentage.toFixed(2)
        });
      }
    });
    setProfitItems(() => profits);
  }

  return (
    <Page>
      <Tabs value={selectedTab} onChange={handleChange} variant="fullWidth">
        <Tab label="Ostetut" />
        <Tab label="Myytävät" />
      </Tabs>
      { selectedTab === 0 && <></>}
      { selectedTab === 1 &&
        <Container disableGutters>
          <Box sx={{background: tokens.colors.highlightLight2, paddingX: tokens.spacing.s, paddingY: tokens.spacing.xxs}}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellTight>{t("app.models.UsageLocation.id")}</TableCellTight>
                  <TableCellTight>{t("app.models.UsageLocation.name")}</TableCellTight>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCellTight>{location?.id}</TableCellTight>
                  <TableCellTight>{location?.name}</TableCellTight>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <Box sx={{background: tokens.colors.depthLight1, marginTop: tokens.spacing.xxs}}>
            <Typography sx={{paddingX: tokens.spacing.s, paddingY: tokens.spacing.xxs}}>{t("app.components.ProfitListView.usedProducts")}</Typography>
            <List component="div" disablePadding>
              {usageItems.map(({usageItem, storageItem}, itemIndex) => {
                const quantityUnit = storageItem.InvoicedQuantity._QuantityUnitCode.toLowerCase();
                const priceUnit = storageItem.UnitPriceAmount._AmountCurrencyIdentifier === AmountCurrencyIdentifier.Eur ? "€" : storageItem.UnitPriceAmount._AmountCurrencyIdentifier;
                return  <ListItem
                    key={`item-${itemIndex}`}
                    sx={{background: (itemIndex % 2 ? tokens.colors.depthLight2 : tokens.colors.depthLight3), paddingX: tokens.spacing.xxs}}
                    disablePadding
                    divider
                  >
                    <Grid container>
                      <Grid item xs={3}>
                        <Box sx={{paddingX: tokens.spacing.xxs}}>
                          <Typography variant="caption">{t("app.components.ProfitListView.batchNumber")}</Typography>
                          <Typography>{storageItem.InvoicedObjectID?.Value}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box sx={{paddingX: tokens.spacing.xxs}}>
                          <Typography variant="caption">{t("app.components.ProfitListView.timestamp")}</Typography>
                          <Typography>{fromDateTimeString(usageItem.UpdatedAt) + ""}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{paddingX: tokens.spacing.xxs}}>
                          <Typography variant="caption">{t("app.components.ProfitListView.article")}</Typography>
                          <Typography>{storageItem.ArticleName}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={3}>
                        <Box sx={{paddingX: tokens.spacing.xxs}}>
                          <Typography variant="caption">{`${t("app.components.ProfitListView.quantity")} (${quantityUnit})`}</Typography>
                          <Typography>{usageItem.Amount}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={5}>
                        <Box sx={{paddingX: tokens.spacing.xxs}}>
                          <Typography variant="caption">{`${t("app.components.ProfitListView.unitPrice")} (${priceUnit}/${quantityUnit})`}</Typography>
                          <Typography>{storageItem.UnitPriceAmount.Value}</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box sx={{paddingX: tokens.spacing.xxs}}>
                          <Typography variant="caption">{`${t("app.components.ProfitListView.totalPrice")} (${priceUnit})`}</Typography>
                          <Typography>{calculateTotalPrice(usageItem.Amount, storageItem.UnitPriceAmount.Value)}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </ListItem>
                }
              )}
            </List>
          </Box>
          <Box sx={{background: tokens.colors.depthLight1, marginTop: tokens.spacing.xxs}}>
            <Typography sx={{paddingX: tokens.spacing.s, paddingY: tokens.spacing.xxs}}>{t("app.components.ProfitListView.salesFromBlock")}</Typography>
            <List component="div" disablePadding>
              { profitItems.map((profitItem, profitIndex) => {
                const quantityUnit = profitItem.storageItem.InvoicedQuantity._QuantityUnitCode.toLowerCase();
                const priceUnit = profitItem.storageItem.UnitPriceAmount._AmountCurrencyIdentifier === AmountCurrencyIdentifier.Eur ? "€" : profitItem.storageItem.UnitPriceAmount._AmountCurrencyIdentifier;
                return <ListItem
                  key={`profit-item-${profitIndex}`}
                  sx={{background: (profitIndex % 2 ? tokens.colors.depthLight2 : tokens.colors.depthLight3), paddingX: tokens.spacing.xxs}}
                  disablePadding
                  divider
                >
                  <Grid container>
                    <Grid item xs={5}>
                      <Box sx={{paddingX: tokens.spacing.xxs}}>
                        <Typography variant="caption">{t("app.components.ProfitListView.article")}</Typography>
                        <Typography>{profitItem.storageItem?.ArticleName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box sx={{paddingX: tokens.spacing.xxs}}>
                        <Typography variant="caption">{`${t("app.components.ProfitListView.quantity")} (${quantityUnit})`}</Typography>
                        <Typography>{profitItem.quantity}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box sx={{paddingX: tokens.spacing.xxs}}>
                        <Typography variant="caption">{`${t("app.components.ProfitListView.price")} (${priceUnit})`}</Typography>
                        <Typography>{profitItem.price}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{paddingX: tokens.spacing.xxs}}>
                        <Typography variant="caption">{`${t("app.components.ProfitListView.profitEuro")} (${priceUnit})`}</Typography>
                        <Typography>{profitItem.profitEuro}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box sx={{paddingX: tokens.spacing.xxs}}>
                        <Typography variant="caption">{t("app.components.ProfitListView.profitPercentage")}</Typography>
                        <Typography>{profitItem.profitPercentage}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </ListItem>
              })}
            </List>
          </Box>
        </Container>
      }
    </Page>
  );
}

export default ProfitView;