import * as Yup from 'yup';
import * as FinvoiceModels from './FinvoiceModels';

export const AmountSchema: Yup.ObjectSchema<FinvoiceModels.Amount> = Yup.object({
  _AmountCurrencyIdentifier:  Yup.mixed<FinvoiceModels.AmountCurrencyIdentifier>().required('Required'),
  Value:                      Yup.string().required('Required')
});

export const InvoicedObjectIDSchema: Yup.ObjectSchema<FinvoiceModels.InvoicedObjectID> = Yup.object({
  _SchemeID:                  Yup.string().required('Required'),
  Value:                      Yup.string().required('Required')
});

export const InvoicedQuantitySchema: Yup.ObjectSchema<FinvoiceModels.InvoicedQuantity> = Yup.object({
  _QuantityUnitCode:          Yup.string().required('Required'),
  _QuantityUnitCodeUN:        Yup.string().required('Required'),
  Value:                      Yup.string().required('Required'),
});

export const InvoiceRowSchema: Yup.ObjectSchema<FinvoiceModels.InvoiceRow> = Yup.object({
  InvoicedObjectID:           InvoicedObjectIDSchema,
  ArticleIdentifier:          Yup.string().required('Required'),
  ArticleName:                Yup.string().required('Required'),
  ArticleDescription:         Yup.string(),
  InvoicedQuantity:           InvoicedQuantitySchema,
  UnitPriceAmount:            AmountSchema,
  UnitPriceNetAmount:         AmountSchema,
  RowPositionIdentifier:      Yup.string().required('Required'),
  RowVatRatePercent:          Yup.string().required('Required'),
  RowVatCode:                 Yup.string().required('Required'),
  RowVatExcludedAmount:       AmountSchema,
  RowAmount:                  AmountSchema
});
