import React from "react";
import { getIn, useFormik } from "formik";
import * as Yup from 'yup';
import { Box, Button, Card, Checkbox, FormControl, FormHelperText, Grid, List, ListItem, ListItemButton, ListItemText, Radio, RadioGroup } from '@mui/material';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useTranslation } from "react-i18next";
import { InvoiceRow } from "../../../models/FinvoiceModels";
import { InvoiceImportFormModel, InvoiceImportRow, StorageItemStateEnum } from "../../../models/StorageModels";
import { GridItem } from "../../common/CommonStyles";

export const InvoiceImportRowSchema: Yup.ObjectSchema<InvoiceImportRow> = Yup.object({
  rowIndex: Yup.number().required('Required'),
  selected: Yup.boolean().required('Required'),
  status: Yup.string().when("selected", {
    is: true,
    then: schema => schema.required('Required')
  })
});

const InvoiceImportFormModelSchema: Yup.ObjectSchema<InvoiceImportFormModel> = Yup.object({
  rows: Yup.array().of(InvoiceImportRowSchema).required('Required')
});

interface Props {
  rows: InvoiceRow[],
  onSubmit: (selected: InvoiceImportRow[]) => void
}

const InvoiceImportForm: React.FC<Props> = ({rows, onSubmit}) => {
  const { t } = useTranslation(["translation"]);

  const initialValues = (): InvoiceImportFormModel => {
    const formikRows = rows.map((row, rowIndex) => ({
      rowIndex,
      selected: false,
      status: ""
    }));
    return { rows: formikRows };
  }

  const submitForm = () => {
    onSubmit(formik.values.rows);
    formik.resetForm();
  }

  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: InvoiceImportFormModelSchema,
    onSubmit: submitForm
  })

  const handleSelectedChange = (rowIndex: number) => (e: React.ChangeEvent<any>) => {
    if (!e.target.checked) {
      // Reset status when row is unchecked
      formik.setFieldValue(`rows[${rowIndex}].status`, "");
    }
    formik.handleChange(e);
  }

  const newItemsToStorage = () => {
    return formik.values.rows.filter(row => row.selected).filter(row => row.status === StorageItemStateEnum.STORAGE).length > 0;
  }

  return (
    <Grid container>
      <GridItem item xs={12}>
        <Card sx={{width: "100%"}}>
          <List component="div" disablePadding>
            <ListItem
              key={`invoice-row-head`}
              sx={{background: tokens.colors.highlightLight2}}
              disablePadding
              divider
            >
              <Grid container sx={{padding: `${tokens.spacing.xxs} ${tokens.spacing.xxs}`}}>
                <GridItem item xs="auto">
                  <ListItemButton
                    sx={{minWidth: 0, padding: 0, marginRight: tokens.spacing.xs, visibility: "hidden"}}
                  >
                    <Checkbox
                      checked={false}
                      disableRipple
                      sx={{padding: 0}}
                    />
                  </ListItemButton>
                </GridItem>
                <GridItem item xs>
                  <ListItemText primaryTypographyProps={{variant: "subtitle2"}} primary={t("app.components.InvoiceListView.articleName")}/>
                </GridItem>
                <GridItem item xs="auto">
                  <ListItemText primaryTypographyProps={{variant: "subtitle2", align: "right"}} primary={t("app.components.InvoiceListView.inStorage")}/>
                </GridItem>
              </Grid>
            </ListItem>
            {rows.map((row, rowIndex) => (
              <ListItem
                key={`invoice-row-${rowIndex}`}
                sx={{background: (rowIndex % 2 ? tokens.colors.depthLight2 : tokens.colors.depthLight3)}}
                disablePadding
                divider
              >
                <Grid container sx={{padding: `${tokens.spacing.xxs} ${tokens.spacing.xxs}`}}>
                  <GridItem item xs="auto">
                    <ListItemButton 
                      // onClick={handleSelectedRow(rowIndex)}
                      sx={{minWidth: 0, padding: 0, marginRight: tokens.spacing.xs}}
                    >
                      <Checkbox
                        onChange={handleSelectedChange(rowIndex)}
                        name={`rows[${rowIndex}].selected`}
                        checked={formik.values.rows[rowIndex].selected}
                        disableRipple
                        sx={{padding: 0}}
                      />
                    </ListItemButton>
                  </GridItem>
                  <GridItem item xs>
                    <ListItemText
                      primaryTypographyProps={{variant: "body2"}} sx={{margin: 0}} 
                      primary={row.ArticleName} 
                      secondary={row.InvoicedObjectID?.Value}
                    />
                  </GridItem>
                  <GridItem item xs="auto" sx={{justifyContent: "flex-end"}}>
                    <FormControl
                      disabled={!formik.values.rows[rowIndex].selected}
                      error={Boolean(getIn(formik.touched, `rows[${rowIndex}].status`)) && Boolean(getIn(formik.errors, `rows[${rowIndex}].status`))}
                    >
                      <RadioGroup
                        row
                        name={`rows[${rowIndex}].status`}
                        value={formik.values.rows[rowIndex].status ?? ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        sx={{justifyContent: "flex-end"}}
                      >
                        <Radio icon={<LocalShippingOutlinedIcon/>} checkedIcon={<LocalShippingIcon/>} value={StorageItemStateEnum.SHIPPING} />
                        <Radio icon={<WarehouseOutlinedIcon/>} checkedIcon={<WarehouseIcon/>} value={StorageItemStateEnum.STORAGE} />
                      </RadioGroup>
                      { Boolean(getIn(formik.touched, `rows[${rowIndex}].status`)) && Boolean(getIn(formik.errors, `rows[${rowIndex}].status`)) &&
                        <FormHelperText sx={{margin: 0, textAlign: "right"}}>{t(`common.validation.${getIn(formik.errors, `rows[${rowIndex}].status`)}`)}</FormHelperText> 
                      }
                    </FormControl>
                  </GridItem>
                </Grid>
              </ListItem>
            ))}
          </List>
        </Card>
      </GridItem>
      <GridItem item xs={12}>
        <Box sx={{display: "flex", width: "100%", justifyContent: "center", marginTop: tokens.spacing.xxs, marginBottom: tokens.spacing.xxs}}>
          <Button
            variant="contained"
            disabled={formik.values.rows.filter(row => row.selected).length < 1}
            onClick={formik.submitForm}
          >
            { newItemsToStorage() ? t("app.components.InvoiceListView.selectStorage") : t("app.components.InvoiceListView.importSelectedRows")}
          </Button>
        </Box>
      </GridItem>
    </Grid>
  );
}

export default InvoiceImportForm;