/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { useAppStateContext } from '../state/AppStateContext';

const HeaderContent = styled.div`
  display: flex;
`

const DemoInfo: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();

  return (
    <HeaderContent>
      <div>
        <IconButton
          onClick={() => appContext.setShowDemoInfo(true)}
          size="small"
          sx={{ ml: tokens.spacing.xs }}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: tokens.colors.brandBase }}>
            <InfoOutlinedIcon/>
          </Avatar>
        </IconButton>
      </div>
      <Dialog open={appContext.showDemoInfo} onClose={() => appContext.setShowDemoInfo(false)}>
        <DialogTitle color={tokens.colors.highlightBase}>
          <InfoOutlinedIcon sx={{verticalAlign: "sub", mr: tokens.spacing.xxs}}/>
          {t("app.components.DemoInfo.title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText color={tokens.colors.blackBase} sx={{mb: tokens.spacing.xs}}>
            <Trans
              i18nKey="app.components.DemoInfo.invoicesInfo"
              components={{
                title: <Typography display="inline" variant="button">{t("app.navigation.invoices")}</Typography>
              }}
            />
          </DialogContentText>
          <DialogContentText color={tokens.colors.blackBase} sx={{mb: tokens.spacing.xs}}>
            {t("app.components.DemoInfo.invoicesInfo2")}
          </DialogContentText>
          <DialogContentText color={tokens.colors.blackBase} sx={{mb: tokens.spacing.xs}}>
            <Trans
              i18nKey="app.components.DemoInfo.storageInfo"
              components={{
                title: <Typography display="inline" variant="button">{t("app.navigation.storage")}</Typography>
              }}
            />
          </DialogContentText>
          <DialogContentText color={tokens.colors.blackBase} sx={{mb: tokens.spacing.xs}}>
            {t("app.components.DemoInfo.storageInfo2")}
          </DialogContentText>
          <DialogContentText color={tokens.colors.blackBase} sx={{mb: tokens.spacing.xs}}>
            <Trans
              i18nKey="app.components.DemoInfo.blockReportInfo"
              components={{
                title: <Typography display="inline" variant="button">{t("app.navigation.profit")}</Typography>
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => appContext.setShowDemoInfo(false)} autoFocus>
            {t("common.actions.close.title")}
          </Button>
        </DialogActions>
      </Dialog>
    </HeaderContent>
  );
}

export default DemoInfo;