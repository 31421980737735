import React, {useState} from 'react';
import { useNavigate } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { suomifiDesignTokens as tokens } from 'suomifi-ui-components'
import { Box, Button, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import LoadingModal from '../modals/LoadingModal'
import * as StorageApi from "../../api/StorageApi";
import { useAppStateContext } from '../../state/AppStateContext';
import Page from '../common/Page';

const LoginView = () => {
  const appContext = useAppStateContext();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string|undefined>(undefined);

  const startDemo = () => {
    setError(undefined);
    setLoading(true);
    StorageApi.SignUpForDemo()
    .then(() => {
      return appContext.refreshAppState();
    })
    .then(() => {
      setLoading(false);
      appContext.setShowDemoInfo(true);
      navigate("/");
    })
    .catch(err => {
      setError("app.login.notFound");
      setLoading(false);
      console.log(err);
    })
  }

  return (
    <Page>
      <Box sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Typography>{t("app.login.demoInfo")}</Typography>
      </Box>

      <Box sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Typography>{t("app.login.demoInfo2")}</Typography>
      </Box>

      { error && 
        <Box sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
          <Typography variant="button" color={tokens.colors.alertBase}>{t(error)}</Typography>
        </Box>
      }
      <div style={{ margin: `${tokens.spacing.m} 0`, display: "flex", justifyContent: "center" }}>
        <Button variant="contained" startIcon={<LoginIcon/>} onClick={() => startDemo()}>
          {t("app.login.loginbutton")}
        </Button>
      </div>

      <Box sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Typography>
          <Trans
            i18nKey="app.login.infotext"
            components={{
              link1: <a href={t("app.login.link1")} target="_blank">Yrityksen digitalous</a>
            }}
          />
        </Typography>
      </Box>
      <Box sx={{display: "flex", justifyContent: "center", textAlign: "center"}}>
        <Typography variant="button">{t("app.login.disclaimer")}</Typography>
      </Box>
      <LoadingModal isOpen={loading} contentLabel={"Loading"} message={t("common.loadingApp")}/>
    </Page>
  )
}

export default LoginView;