import { StorageItem } from "../models/StorageModels"

export const getStorageLocations = (storageItem: StorageItem): string[] => {
  return storageItem.Storages.filter(it => !!it.LocationId).map(it => it.LocationId);
}

export const getInvoicedQuantity = (storageItem: StorageItem): number => {
  return parseInt(storageItem.InvoicedQuantity.Value);
}

export const getDeliveredQuantity = (storageItem: StorageItem): number => {
  return storageItem.Deliveries.reduce<number>((total, it) => total + it.Amount, 0);
}

export const getWaitingForDeliveryQuantity = (storageItem: StorageItem): number => {
  const delivered = getDeliveredQuantity(storageItem);
  const invoiced = getInvoicedQuantity(storageItem);
  return invoiced - delivered;
}

export const getQuantityInStorages = (storageItem: StorageItem): number => {
  return storageItem.Storages.reduce<number>((total, it) => total + it.Amount, 0);
}

