import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { StorageItem } from '../models/StorageModels';

const apiRootUrl = process.env.REACT_APP_STORAGE_API_URL || 'http://localhost:7075/api'
const apiKey = process.env.REACT_APP_STORAGE_API_KEY || "";
const tokenKey = "x-luomudemo-access-token";  

const requestInterceptor = (config: InternalAxiosRequestConfig<any>) => {
  const token = sessionStorage.getItem(tokenKey);
  if (token && config.headers) {
    config.headers[tokenKey] = token;
  }
  return config;
}

const responseInterceptor = (response: AxiosResponse<any>) => {
  const token = response.headers[tokenKey];
  if (token) {
    sessionStorage.setItem(tokenKey, token);
  }
  return response;
}

const api = axios.create({
  baseURL: apiRootUrl,
  headers: {
    "x-functions-key": apiKey
  },
  withCredentials: true
});

api.interceptors.request.use(requestInterceptor);
api.interceptors.response.use(responseInterceptor);

export async function SignUpForDemo(): Promise<AxiosResponse<any>> {
  return api.post("/SignUpForDemo");
}

export async function DeleteDemoAccount(): Promise<AxiosResponse<any>> {
  return api.post("/DeleteDemoAccount")
  .then(response => {
    sessionStorage.removeItem(tokenKey);
    return response;
  });
}

export async function GetCurrentDemoAccount(): Promise<AxiosResponse<any>> {
  const token = sessionStorage.getItem(tokenKey);
  if (token) {
    return api.get("/GetCurrentDemoAccount");
  }
  return new Promise((resolve, reject) => reject("Not logged in"));
}

export async function GetStorageItems(): Promise<AxiosResponse<StorageItem[]>> {
  return api.get<StorageItem[]>(`/GetStorageItems`);
}

export async function PostStorageItems(storageItems: StorageItem[]): Promise<AxiosResponse<StorageItem[]>> {
  return api.post<StorageItem[]>(`/PostStorageItems`, storageItems);
}
