/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppStateContext } from '../../state/AppStateContext';
import { Avatar, Divider, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { ButtonLink, ButtonLinkSmall } from './InputStyles';
import { useNavigate } from 'react-router';

const HeaderContent = styled.div`
  display: flex;
`

export const SelectLanguage = styled.div`
  display: flex;
  text-align: right;
  line-height: 1;
`

const UserMenu: React.FC = () => {
  const { t, i18n } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const appContext = useAppStateContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    appContext.logout()
    .then(() => {
      navigate("/login");
    });
  }

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <HeaderContent>
      <div>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: tokens.spacing.xs }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, bgcolor: tokens.colors.brandBase }}>
            <MenuIcon/>
          </Avatar>
        </IconButton>
      </div>
      <Menu
        anchorEl={anchorEl}
        id={"user-menu"}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem sx={{justifyContent: "space-evenly"}}>
          <ButtonLink
            style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`, color: tokens.colors.brandBase}}
            onClick={() => changeLanguage('fi') }
          >
            FI
          </ButtonLink>
          <ButtonLink
            style={{textTransform: "uppercase", fontSize: '22px', margin: `0 ${tokens.spacing.xxs}`, color: tokens.colors.brandBase}}
            onClick={() => changeLanguage('en') }
          >
            EN
          </ButtonLink>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <DeleteForeverIcon color="primary"/>
          </ListItemIcon>
          <ButtonLinkSmall style={{textTransform: "uppercase", lineHeight: 1.2, color: tokens.colors.brandBase}} onClick={logout}>
            {t("app.header.logout")}
          </ButtonLinkSmall>
        </MenuItem>
      </Menu>
    </HeaderContent>
  );
}

export default UserMenu;