/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { suomifiDesignTokens as tokens } from "suomifi-ui-components";
import { RoutePaths } from '../App';
import { Grid, Typography } from '@mui/material';
import { useAppStateContext } from '../state/AppStateContext';

const MobileAppMenuContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 0;
  width: 100%;
`;

const NavItem: React.FC<{to: string, label: string}> = ({ to, label }) => {
  let match = useMatch(to);

  const styles = {
    gridItem: css({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      '&.match': {
        background: tokens.colors.highlightBase,
        borderRadius: `0 0 ${tokens.spacing.s} ${tokens.spacing.s}`
      }
    }),
    link: css({
      color: tokens.colors.blackBase,
      lineHeight: 2.5,
      flexGrow: 1,
      textAlign: "center"
    }),
    text: css({
      color: tokens.colors.highlightBase,
      '&.match': {
        color: tokens.colors.whiteBase
      }
    })
  };

  return (
    <Grid item xs css={styles.gridItem} className={match ? "match" : ""}>
      <Link to={to} 
      css={styles.link}>
        <Typography variant="button" css={styles.text} className={match ? "match" : ""}>{label}</Typography>
      </Link>
    </Grid>
  );
};

const MobileAppMenu: React.FC = () => {
  const { t } = useTranslation(["translation"]);
  const appContext = useAppStateContext();

  const styles = {
    gridContainer: css({
      background: tokens.colors.highlightLight2
    }),
    link: css({
      color: tokens.colors.blackBase,
      lineHeight: 2.5,
      padding: `${tokens.spacing.xxs, 0}`,
      marginRight: tokens.spacing.l
    })
  };

  return appContext.loggedIn ? (
    <MobileAppMenuContainer>
      <Grid container css={styles.gridContainer}>
        { RoutePaths.filter(it => it.visibleInNav).map((it, i) => 
            <NavItem key={`navlistitem-${i}`} to={it.to} label={t(`app.navigation.${it.label}`)} />
          )
        }
      </Grid>
    </MobileAppMenuContainer>
  ) : <></>;
}

export default MobileAppMenu;