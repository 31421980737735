export interface XmlRoot {
  Finvoice: Finvoice;
}

export interface Finvoice {
  MessageTransmissionDetails:             MessageTransmissionDetails;
  SellerPartyDetails:                     SellerPartyDetails;
  SellerOrganisationUnitNumber:           string;
  SellerInformationDetails:               SellerInformationDetails;
  InvoiceRecipientPartyDetails:           InvoiceRecipientPartyDetails;
  InvoiceRecipientOrganisationUnitNumber: string;
  InvoiceRecipientLanguageCode:           string;
  BuyerPartyDetails:                      BuyerPartyDetails;
  BuyerOrganisationUnitNumber:            string;
  DeliveryPartyDetails:                   DeliveryPartyDetails;
  DeliveryOrganisationUnitNumber:         string;
  DeliveryDetails:                        DeliveryDetails;
  InvoiceDetails:                         InvoiceDetails;
  InvoiceRow:                             InvoiceRow[];
  EpiDetails:                             EpiDetails;
  "_xmlns:xsi":                           string;
  _Version:                               string;
  "_xsi:noNamespaceSchemaLocation":       string;
}

export interface BuyerPartyDetails {
  BuyerPartyIdentifier:      BuyerPartyIdentifier;
  BuyerOrganisationName:     string;
  BuyerOrganisationTaxCode:  string;
  BuyerCode:                 Code;
  BuyerPostalAddressDetails: BuyerPostalAddressDetails;
}

export interface Code {
  _IdentifierType: string;
  Value:           string;
}

export interface BuyerPartyIdentifier {
  _SchemeID: string;
  Value:     string;
}

export interface BuyerPostalAddressDetails {
  BuyerStreetName:         string;
  BuyerTownName:           string;
  BuyerPostCodeIdentifier: string;
  CountryCode:             string;
}

export interface DeliveryDetails {
  DeliveryDate:           DeliveryDateClass;
  DeliveryMethodText:     string;
  DeliveryNoteIdentifier: string;
}

export interface DeliveryDateClass {
  _Format: string;
  Value:   string;
}

export interface DeliveryPartyDetails {
  DeliveryPartyIdentifier:      string;
  DeliveryOrganisationName:     string;
  DeliveryOrganisationTaxCode:  string;
  DeliveryCode:                 Code;
  DeliveryPostalAddressDetails: DeliveryPostalAddressDetails;
}

export interface DeliveryPostalAddressDetails {
  DeliveryStreetName:         string;
  DeliveryTownName:           string;
  DeliveryPostCodeIdentifier: string;
  CountryCode:                string;
}

export interface EpiDetails {
  EpiIdentificationDetails:     EpiIdentificationDetails;
  EpiPartyDetails:              EpiPartyDetails;
  EpiPaymentInstructionDetails: EpiPaymentInstructionDetails;
}

export interface EpiIdentificationDetails {
  EpiDate:      DeliveryDateClass;
  EpiReference: string;
}

export interface EpiPartyDetails {
  EpiBfiPartyDetails:         EpiBfiPartyDetails;
  EpiBeneficiaryPartyDetails: EpiBeneficiaryPartyDetails;
}

export interface EpiBeneficiaryPartyDetails {
  EpiNameAddressDetails: string;
  EpiBei:                string;
  EpiAccountID:          EpiRemittanceInfoIdentifier;
}

export interface EpiRemittanceInfoIdentifier {
  _IdentificationSchemeName: string;
  Value:                     string;
}

export interface EpiBfiPartyDetails {
  EpiBfiIdentifier: EpiRemittanceInfoIdentifier;
}

export interface EpiPaymentInstructionDetails {
  EpiPaymentInstructionId:     string;
  EpiRemittanceInfoIdentifier: EpiRemittanceInfoIdentifier;
  EpiInstructedAmount:         Amount;
  EpiCharge:                   EpiCharge;
  EpiDateOptionDate:           DeliveryDateClass;
}

export interface EpiCharge {
  _ChargeOption: string;
  Value:         string;
}

export interface Amount {
  _AmountCurrencyIdentifier: string;
  Value:                     string;
}

export enum AmountCurrencyIdentifier {
  Eur = "EUR",
}

export interface InvoiceDetails {
  InvoiceTypeCode:               InvoiceTypeCode;
  InvoiceTypeCodeUN:             string;
  InvoiceTypeText:               string;
  OriginCode:                    string;
  OriginText:                    string;
  InvoiceNumber:                 string;
  InvoiceDate:                   DeliveryDateClass;
  SellerReferenceIdentifier:     string;
  OrderIdentifier:               string;
  OrdererName:                   string;
  SalesPersonName:               string;
  RowsTotalVatExcludedAmount:    Amount;
  InvoiceTotalVatExcludedAmount: Amount;
  InvoiceTotalVatAmount:         Amount;
  InvoiceTotalVatIncludedAmount: Amount;
  VatSpecificationDetails:       VatSpecificationDetail[];
  InvoiceFreeText:               string;
  PaymentTermsDetails:           PaymentTermsDetails;
}

export interface InvoiceTypeCode {
  _CodeListAgencyIdentifier: string;
  Value:                     string;
}

export interface PaymentTermsDetails {
  PaymentTermsFreeText:      string;
  FreeText:                  FreeText;
  InvoiceDueDate:            DeliveryDateClass;
  PaymentOverDueFineDetails: PaymentOverDueFineDetails;
}

export interface FreeText {
  Value: string;
}

export interface PaymentOverDueFineDetails {
  PaymentOverDueFinePercent: string;
}

export interface VatSpecificationDetail {
  VatBaseAmount:  Amount;
  VatRatePercent: string;
  VatCode:        string;
  VatRateAmount:  Amount;
  VatFreeText:    string;
}

export interface InvoiceRecipientPartyDetails {
  InvoiceRecipientOrganisationName: string;
  InvoiceRecipientCode:             Code;
}

export interface InvoicedObjectID {
  _SchemeID: string;
  Value:     string;
}

export interface InvoiceRow {
  InvoicedObjectID?:     BuyerPartyIdentifier;
  ArticleIdentifier:     string;
  ArticleName:           string;
  ArticleDescription?:   string;
  InvoicedQuantity:      InvoicedQuantity;
  UnitPriceAmount:       Amount;
  UnitPriceNetAmount:    Amount;
  RowPositionIdentifier: string;
  RowVatRatePercent:     string;
  RowVatCode:            string;
  RowVatExcludedAmount:  Amount;
  RowAmount:             Amount;
}

export interface InvoicedQuantity {
  _QuantityUnitCode:   string;
  _QuantityUnitCodeUN: string;
  Value:               string;
}

export interface MessageTransmissionDetails {
  MessageSenderDetails:   MessageSenderDetails;
  MessageReceiverDetails: MessageReceiverDetails;
  MessageDetails:         MessageDetails;
}

export interface MessageDetails {
  MessageIdentifier:       string;
  MessageTimeStamp:        Date;
  SpecificationIdentifier: string;
}

export interface MessageReceiverDetails {
  ToIdentifier:    string;
  ToIntermediator: string;
}

export interface MessageSenderDetails {
  FromIdentifier:    BuyerPartyIdentifier;
  FromIntermediator: string;
}

export interface SellerInformationDetails {
  SellerAccountDetails: SellerAccountDetails;
}

export interface SellerAccountDetails {
  SellerAccountID: EpiRemittanceInfoIdentifier;
  SellerBic:       EpiRemittanceInfoIdentifier;
}

export interface SellerPartyDetails {
  SellerPartyIdentifier:      BuyerPartyIdentifier;
  SellerOrganisationName:     string;
  SellerOrganisationTaxCode:  string;
  SellerCode:                 Code;
  SellerPostalAddressDetails: SellerPostalAddressDetails;
}

export interface SellerPostalAddressDetails {
  SellerStreetName:         string;
  SellerTownName:           string;
  SellerPostCodeIdentifier: string;
  CountryCode:              string;
}
